import React, { Suspense, useEffect } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Auth, Dashboard, Login, ProfileSetup, Register, RegisterInvalid } from 'modules';
import { AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { ResetPassword } from 'modules/Auth/pages/ResetPassword';
import { PrivateOutlet } from 'application/PrivateOutlet';
import { DashboardOutlet } from 'modules/Dashboard/DashboardOutlet';
import { selectAuthToken, tryGetProfile, useAppDispatch, useSelector } from 'store';
import { InlineDialog } from 'stories';
import { CoursePage } from 'modules/+CoursePage';
import { Toaster } from 'react-hot-toast';

const LazyProjectCreate = React.lazy(() => import('../modules/+ProjectCreate'));

export const App = () => {
  const token = useSelector(selectAuthToken);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    if (token) {
      dispatch(tryGetProfile());
    }
  }, [token, dispatch]);

  return (
    <AnimatePresence exitBeforeEnter initial={true}>
      {/* key={`/${getLocationLevelPath(location.pathname)}`} */}
      <Toaster />
      <Routes location={location}>
        <Route path="/*" element={<PrivateOutlet />}>
          <Route path="/*" element={<DashboardOutlet />}>
            <Route path=":project_id" element={<Dashboard />} />
            <Route path=":project_id/*" element={<Dashboard />} />
            <Route path=":project_id/education/:education_id/*" element={<CoursePage />} />
            <Route
              path=":project_id/education/:education_id/:video_id/*"
              element={<CoursePage />}
            />
          </Route>
        </Route>

        <Route path="create" element={<PrivateOutlet />}>
          <Route
            index
            element={
              <InlineDialog>
                <Suspense fallback={<>Loading...</>}>
                  <LazyProjectCreate />
                </Suspense>
              </InlineDialog>
            }
          />
        </Route>

        <Route
          path="profile-setup"
          element={
            <Auth checkForToken={false}>
              <InlineDialog>
                <ProfileSetup />
              </InlineDialog>
            </Auth>
          }
        />

        <Route
          path="sign-in"
          element={
            <Auth>
              <InlineDialog>
                <Login />
              </InlineDialog>
            </Auth>
          }
        />

        <Route
          path="reset-password"
          element={
            <Auth>
              <InlineDialog>
                <ResetPassword />
              </InlineDialog>
            </Auth>
          }
        />

        <Route
          path="sign-up"
          element={
            <Auth>
              <InlineDialog>
                <Outlet />
              </InlineDialog>
            </Auth>
          }
        >
          <Route index element={<RegisterInvalid />} />
          <Route path="invalid" element={<RegisterInvalid />} />
          <Route path=":refer_key" element={<Register />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};
