import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

type Filters = {
  status: string;
  date: string;
  source: string;
};

export const FilterSearchParams: React.FC = () => {
  const { values } = useFormikContext<Filters>();
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(searchParams);

  useEffect(() => {
    setSearchParams(values);
  }, [values]);

  return null;
};
