import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useSelector } from 'store';
import { resetCourseState } from 'store/modules/courseSlice';
import { selectCourse } from 'store/selectors/course';
import { DialogHeader } from 'stories/Dialog/components';
import { RatingStars } from 'stories/RatingStars';
import { AccordionFilesList } from '../AccordionFilesList';
import { VideoCourseList } from '../VideoCourseList';
import styles from './CourseInfoSidebar.module.scss';

export const CourseInfoSidebar = () => {
  const { activeCourse: courseItem } = useSelector(selectCourse);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.project_id;

  const onCloseClick = () => {
    navigate(`/${projectId}/education`);
    dispatch(resetCourseState());
  };

  return (
    <div className={styles.courseInfo}>
      <DialogHeader className={styles.header} onClose={onCloseClick} title="Просмотр курса" />
      <div className={styles.body}>
        <div className={styles.wrapper}>
          <RatingStars rating={courseItem?.rating} />
          <h1 className={styles.title}>{courseItem?.name}</h1>
        </div>
        <AccordionFilesList items={courseItem?.documents} />
        <VideoCourseList courseVideos={courseItem?.videos} />
      </div>
    </div>
  );
};
