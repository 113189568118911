import { PaginatedResponseDTO } from 'api';
import axios from 'axios';

// * Profile
export interface ProfileLinkDTO {
  link: string;
  subname: string;
  social: {
    id: number;
    name: string;
    icon: string;
  };
}

export interface ProfileRefererDTO {
  id: number;
  login: string;
  name: string;
  lastname: string;
  full_name: string;
  short_name: string;
  balance: number;
  email: string;
  photo: string;
  refer_key: string;
  created_at: string;
  links: ProfileLinkDTO[];
}
export interface ProfileDTO {
  balance: number;
  created_at: string;
  email: string;
  full_name: string;
  id: number;
  lastname: string;
  links: ProfileLinkDTO[];
  login: string;
  name: string;
  photo: string;
  short_name: string;
}

export const getProfile = () => {
  return axios.get<{ data: ProfileDTO }>('/api/user/me');
};

export interface ProjectDTO {
  created_at: string;
  description: string;
  end_at: string;
  id: number;
  name: string;
  photo: string;
  short_name: string;
  start_at: string;
  cover: null;
}

export const getProjects = () => {
  return axios.get<PaginatedResponseDTO<ProjectDTO>>('/api/project/with_me/paginate');
};

export interface IUpdateProfilePayload {
  photo: File;
  name: string;
  lastname: string;
  login: string;
}

export const updateProfile = (data: IUpdateProfilePayload) => {
  const formData = new FormData();

  if (data.photo) {
    formData.append('photo', data.photo);
  }

  formData.append('name', data.name);
  formData.append('lastname', data.lastname);
  formData.append('login', data.login);

  return axios.post<{ data: ProfileDTO }>('/api/user/update_me', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
