import { FC } from 'react';
import cn from 'classnames';

import styles from './Split.module.scss';

interface ISplit {
  className?: string;
}

export const Split: FC<ISplit> = ({ className }) => {
  return <hr className={cn(styles.split, className)} />;
};
