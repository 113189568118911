import { Formik, Form, FormikProps } from 'formik';
import { FC, useEffect, useRef } from 'react';
import { Control, DialogContainer } from 'stories';
import { DialogFooter, IDialogFooterButton } from 'stories/Dialog/shared/DialogFooter';

import styles from './RefCardDialog.module.scss';

import { HSplit } from 'stories/HSplit';

type FormValues = { [x: string]: unknown };

interface IRefCardDialogProps {
  name?: string;
  type?: string;
  source?: string;
  trafic?: string;
  companyName?: string;
  buttons?: IDialogFooterButton[];
}

export const RefCardDialog: FC<IRefCardDialogProps> = props => {
  const { name, source } = props;

  const formikRef = useRef<FormikProps<FormValues>>();

  const initialValues = {
    name: '',
    type: '',
    source: '',
    trafic: '',
    companyName: ''
  };

  const onSubmit = async (values: typeof initialValues) => {
    console.log(values);
  };

  useEffect(() => {
    if (!name || !source) return;
    formikRef.current.setFieldValue('name', name);
    formikRef.current.setFieldValue('source', source);
  }, [name, source]);

  return (
    <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit}>
      {({ touched }) => (
        <Form className={styles.root}>
          <DialogContainer>
            <div className={styles.body}>
              <Control
                control="input"
                name="name"
                label="Название"
                placeholder="Рефссылка..."
                classNameGroup={styles.group}
              />
              <Control
                control="select"
                options={[
                  { id: 0, name: 'Новая' },
                  { id: 1, name: 'В процессе' }
                ]}
                name="type"
                label="Тип ссылки"
                placeholder="Выбрать тип ссылки"
                classNameGroup={styles.group}
              />
              <HSplit />
              <Control
                control="input"
                name="source"
                label="Источник кампании"
                placeholder="google, yandex, vk"
                classNameGroup={styles.group}
              />
              <Control
                control="input"
                name="trafic"
                label="Тип трафика"
                placeholder="cpc, email, banner"
                classNameGroup={styles.group}
              />
              <Control
                control="input"
                name="companyName"
                label="Название кампании"
                placeholder="promo, discount, sale"
                classNameGroup={styles.group}
              />
            </div>
          </DialogContainer>
          <DialogFooter
            buttons={props.buttons.map(button => {
              button.disabled = Object.keys(touched).length === 0;
              return button;
            })}
          />
        </Form>
      )}
    </Formik>
  );
};
