import { FC } from 'react';
import { Icon, IIconProps } from 'stories';

import styles from './ArticleAction.module.scss';

export interface IArticleActionProps {
  icon: IIconProps;
  title: string;
  onClick: () => void;
}

export const ArticleAction: FC<IArticleActionProps> = ({ icon, title, onClick }) => {
  return (
    <button onClick={onClick} className={styles.button}>
      <Icon className={styles.icon} viewBox={icon.viewBox} id={icon.id}></Icon>
      {title}
    </button>
  );
};
