import { FC, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Control, Dialog, Icon, IDialogType } from 'stories';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Form, Formik } from 'formik';
import { RefCardDialog } from '../RefCardDialog';
import { useBreakpoints } from 'hooks';
import styles from './RefCard.module.scss';

import icon_document_24 from 'assets/sprite/24/document_24.svg';
import icon_approve_20 from 'assets/sprite/20/approve_20.svg';
import icon_dots_20 from 'assets/sprite/20/dots_20.svg';

interface IRefCard {
  name: string;
  link: string;
  clicks: string;
  sales: string;
  date: string;
  source: string;
}

export const RefCard: FC<IRefCard> = ({ link, name, clicks, sales, date, source }) => {
  const [initialValues, setInitialValues] = useState({
    link: '',
    name: '',
    clicks: '',
    sales: '',
    date: '',
    source: ''
  });

  const dialogRef = useRef<IDialogType>();

  const { isDesktopMin } = useBreakpoints();

  const openDialog = () => {
    dialogRef.current.open();
  };

  useEffect(() => {
    setInitialValues({ link, name, clicks, sales, date, source });
  }, []);

  const onSubmit = () => {};

  const desktopTmp = (
    <div className={styles.root}>
      <span className={styles.title}>{name}</span>
      <div className={styles.middle}>
        <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={onSubmit}>
          {() => {
            return (
              <Form className={styles.form}>
                <div className={styles.group}>
                  <Control
                    classNameGroup={styles.controlGroup}
                    classNameField={styles.controlField}
                    control="input"
                    type="text"
                    name="link"
                    errorField={false}
                    longer
                    placeholder={initialValues.link}
                    disabled
                  />
                  <CopyToClipboard text={initialValues.link}>
                    <motion.button
                      className={styles.actionCopy}
                      whileTap={{ backgroundColor: styles.c_green }}
                      type="button"
                    >
                      <Icon
                        className={styles.actionIcon}
                        viewBox={icon_document_24.viewBox}
                        id={icon_document_24.id}
                      ></Icon>
                      <span className={styles.actionDesc}>Скоп.</span>
                    </motion.button>
                  </CopyToClipboard>
                </div>
              </Form>
            );
          }}
        </Formik>
        <div className={styles.stats}>
          <span className={styles.clicks}>{clicks} Кликов</span>
          <span className={styles.sales}>{sales} Продаж</span>
          <span className={styles.date}>{date}</span>
        </div>
      </div>
      <div className={styles.tools}>
        <button className={styles.action} type="button" onClick={openDialog}>
          <Icon className={styles.icon} viewBox={icon_dots_20.viewBox} id={icon_dots_20.id}></Icon>
        </button>
      </div>
    </div>
  );

  const mobileTmp = (
    <div className={styles.root}>
      <div className={styles.head}>
        <span className={styles.title}>{name}</span>
        <div className={styles.tools}>
          <button className={styles.action} type="button" onClick={openDialog}>
            <Icon
              className={styles.icon}
              viewBox={icon_dots_20.viewBox}
              id={icon_dots_20.id}
            ></Icon>
          </button>
        </div>
      </div>
      <div className={styles.middle}>
        <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={onSubmit}>
          {() => {
            return (
              <Form className={styles.form}>
                <div className={styles.group}>
                  <Control
                    classNameGroup={styles.controlGroup}
                    classNameField={styles.controlField}
                    control="input"
                    type="text"
                    name="link"
                    errorField={false}
                    longer
                    placeholder={initialValues.link}
                    disabled
                  />
                  <CopyToClipboard text={initialValues.link}>
                    <motion.button
                      className={styles.actionCopy}
                      whileTap={{ backgroundColor: styles.c_green }}
                      type="button"
                    >
                      <Icon
                        className={styles.actionIcon}
                        viewBox={icon_document_24.viewBox}
                        id={icon_document_24.id}
                      ></Icon>
                      <span className={styles.actionDesc}>Скоп.</span>
                    </motion.button>
                  </CopyToClipboard>
                </div>
              </Form>
            );
          }}
        </Formik>
        <div className={styles.stats}>
          <span className={styles.clicks}>{clicks} Кликов</span>
          <span className={styles.sales}>{sales} Продаж</span>
          <span className={styles.date}>{date}</span>
        </div>
      </div>
    </div>
  );

  const renderTemplate = () => {
    const isDestopTmp = isDesktopMin;

    if (isDestopTmp) {
      return desktopTmp;
    }

    return mobileTmp;
  };

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {renderTemplate()}
      <Dialog ref={dialogRef} title={'Просмотр заявки'}>
        <RefCardDialog
          name={name}
          source={source}
          buttons={[
            {
              title: 'Сохранить',
              icon: icon_approve_20,
              type: 'submit'
            }
          ]}
        />
      </Dialog>
    </motion.div>
  );
};
