import { Control } from 'stories';
import { SettingItem } from '../Settingitem/SettingItem';

import styles from './Support.module.scss';

export const Support = () => {
  const options = [
    { id: '0', name: 'Telegram' },
    { id: '1', name: 'Viber' }
  ];

  return (
    <SettingItem title="Ссылки поддержки" desc="Укажите до 4х ссылок для поддержки">
      <div className={styles.support}>
        <div className={styles.list}>
          <div className={styles.row}>
            <div className={styles.type}>
              <Control
                control="select"
                type="text"
                options={options}
                name="link_01"
                label="Тип ссылки 01"
                placeholder={'Platforma MLM'}
              />
            </div>
            <div className={styles.link}>
              <Control
                control="input"
                type="text"
                name="Ссылка 01"
                label="Короткое имя проекта"
                placeholder={'Укажите ссылку...'}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.type}>
              <Control
                control="select"
                type="text"
                options={options}
                name="link_02"
                label="Тип ссылки 02"
                placeholder={'Platforma MLM'}
              />
            </div>
            <div className={styles.link}>
              <Control
                control="input"
                type="text"
                name="Ссылка 02"
                label="Короткое имя проекта"
                placeholder={'Укажите ссылку...'}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.type}>
              <Control
                control="select"
                type="text"
                options={options}
                name="link_03"
                label="Тип ссылки 03"
                placeholder={'Platforma MLM'}
              />
            </div>
            <div className={styles.link}>
              <Control
                control="input"
                type="text"
                name="Ссылка 03"
                label="Короткое имя проекта"
                placeholder={'Укажите ссылку...'}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.type}>
              <Control
                control="select"
                type="text"
                options={options}
                name="link_04"
                label="Тип ссылки 04"
                placeholder={'Platforma MLM'}
              />
            </div>
            <div className={styles.link}>
              <Control
                control="input"
                type="text"
                name="Ссылка 04"
                label="Короткое имя проекта"
                placeholder={'Укажите ссылку...'}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.supportLinks}></div>
    </SettingItem>
  );
};
