import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import storage from 'redux-persist/lib/storage';

import auth from 'store/modules/authSlice';
import csrf from 'store/modules/csrfSlice';
import config from 'store/modules/configSlice';
import user from 'store/modules/userSlice';
import education from 'store/modules/educationSlice';
import course from 'store/modules/courseSlice';
import projects from 'store/modules/projectsSlice';
import dashboard from 'store/modules/pages/dashboardSlice';
import team from 'store/modules/pages/teamSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'csrf', 'projects'],
  version: 1
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth,
    csrf,
    config,
    user,
    projects,
    dashboard,
    team,
    education,
    course
  })
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk as ThunkMiddleware],
  devTools: process.env.NODE_ENV !== 'production'
});

export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);

export * from './selectors';
export * from './modules';
export * from './utils';
export * from './store.types';
