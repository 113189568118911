import {
  DashboardPage,
  Header,
  ListsPage,
  MobileBar,
  NotFoundPage,
  Sidebar,
  TeamPage
} from 'modules/Dashboard';
import { AnalyticsPage } from './pages/AnalyticsPage';
import { ReferralPage } from './pages/ReferralPage';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { getLocationLevelPath } from 'utils';
import { tryGetAvailableTeammateStatuses, useAppDispatch } from 'store';
import { useEffect } from 'react';
import { SettingsPage } from './pages/SettingsPage';
import { useBreakpoints } from 'hooks';
import { EducationPage } from './pages/EducationPage';

import styles from './Dashboard.module.scss';

export const Dashboard = () => {
  const dispatch = useAppDispatch();

  const { isTabletMax } = useBreakpoints();

  useEffect(() => {
    dispatch(tryGetAvailableTeammateStatuses());
  }, [dispatch]);

  return (
    <>
      <Sidebar />
      <Header />
      <div className={styles.content}>
        <div className={styles.container}>
          <AnimatePresence exitBeforeEnter initial={true}>
            <Routes location={location} key={getLocationLevelPath(location.pathname, 2)}>
              <Route index element={<Navigate to="dashboard" />} />
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="lists" element={<ListsPage />} />
              <Route path="team" element={<TeamPage />} />
              <Route path="education" element={<EducationPage />} />
              <Route path="analytics" element={<AnalyticsPage />} />
              <Route path="referral" element={<ReferralPage />} />
              <Route path="settings" element={<SettingsPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </AnimatePresence>
        </div>
      </div>
      {isTabletMax && <MobileBar />}
    </>
  );
};
