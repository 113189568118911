import { useSelector } from 'store';
import { selectDashboardReferrer } from 'store/selectors/dashboard';
import { DHasReferer } from './DHasReferer';
import { DNoReferer } from './DNoReferer';

export const DInviterInfo = () => {
  const referer = useSelector(selectDashboardReferrer);

  return referer ? <DHasReferer referer={referer} /> : <DNoReferer />;
};
