import { DPlate } from './DPlate';
import { Icon } from 'stories';
import { useSelector } from 'store';
import { selectDashboardSupport } from 'store/selectors/dashboard';

import styles from './DSupport.module.scss';

import icon_telegram from 'assets/sprite/support/telegram.svg';
import icon_officialSite from 'assets/sprite/support/officialSite.svg';
import icon_viber from 'assets/sprite/support/viber.svg';

const getIconByType = (type: string) => {
  switch (type) {
    case 'telegram':
      return icon_telegram;
    case 'viber':
      return icon_viber;
    case 'web':
    default:
      return icon_officialSite;
  }
};

export const DSupport = () => {
  const support = useSelector(selectDashboardSupport);

  return (
    <DPlate title="Поддержка">
      <div className={styles.row}>
        {support.slice(0, 4).map((item, index) => {
          const icon = getIconByType(item.social.icon);
          return (
            <div key={index} className={styles.col}>
              <div className={styles.soc}>
                <div className={styles.preview}>
                  <Icon className={styles.icon} viewBox={icon.viewBox} id={icon.id}></Icon>
                </div>
                <div className={styles.title}>
                  {item.social.name}
                  {item.subname && (
                    <>
                      <br />
                      {item.subname}
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </DPlate>
  );
};
