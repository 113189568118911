import { useEffect } from 'react';
import { tryGetProjects, useAppDispatch } from 'store';
import { Outlet } from 'react-router-dom';
import { useSetSelectedProject } from './pages/useSetSelectedProject';

export const DashboardOutlet = () => {
  const dispatch = useAppDispatch();
  const { loaded } = useSetSelectedProject();

  useEffect(() => {
    dispatch(tryGetProjects());
  }, [dispatch]);

  if (!loaded) {
    return <div>Loading...</div>;
  }

  return <Outlet />;
};
