import { FC } from 'react';
import { Control } from 'stories';

import styles from './NotificationSettings.module.scss';

interface IItemProps {
  title: string;
  name: string;
}

export const Item: FC<IItemProps> = ({ title, name }) => {
  return (
    <div className={styles.item}>
      <span className={styles.itemTitle}>{title}</span>
      <div className={styles.itemBoxes}>
        <Control
          classNameLabel={styles.label}
          control="checkbox"
          label="Email"
          name={`${name}Email`}
        />
        <Control
          classNameLabel={styles.label}
          control="checkbox"
          label="Telegram"
          name={`${name}Telegram`}
        />
        <Control
          classNameLabel={styles.label}
          control="checkbox"
          label="Push"
          name={`${name}Push`}
        />
      </div>
    </div>
  );
};
