import { NavLink } from 'react-router-dom';
import { Icon } from 'stories';
import { sidebarNavConfig } from './MobileBar.config';
import cn from 'classnames';

import styles from './MobileBar.module.scss';

export const MobileBar = () => {
  return (
    <div className={styles.nav}>
      {sidebarNavConfig.map((route, index) => {
        return (
          <NavLink
            className={({ isActive }) => cn(styles.link, isActive && styles.isSelected)}
            key={index}
            to={`./${route.url}`}
          >
            <Icon className={styles.icon} viewBox={route.icon.viewBox} id={route.icon.id}></Icon>
            <span className={styles.title}>{route.name}</span>
          </NavLink>
        );
      })}
    </div>
  );
};
