import { Formik, Form, FormikProps } from 'formik';
import { FC, useEffect, useRef } from 'react';
import { Control, DialogContainer } from 'stories';
import { DialogFooter, IDialogFooterButton } from 'stories/Dialog/shared/DialogFooter';

import styles from './ListsCardDialog.module.scss';

import { HSplit } from 'stories/HSplit';

type FormValues = { [x: string]: unknown };

interface IListCardDialogProps {
  id?: string;
  username?: string;
  status_id?: number;
  newForm?: boolean;
  buttons: IDialogFooterButton[];
}

export const ListsCardDialog: FC<IListCardDialogProps> = props => {
  const { username, status_id } = props;

  const formikRef = useRef<FormikProps<FormValues>>();

  const initialValues = {
    status_id: '',
    username: '',
    phone: '',
    whatsapp: false,
    telegram: false,
    viber: false,
    telegramLogin: '',
    source: ''
  };

  const onSubmit = async (values: typeof initialValues) => {
    console.log(values);
  };

  useEffect(() => {
    if (!username && !status_id) return;
    formikRef.current.setFieldValue('status_id', status_id.toString());
    formikRef.current.setFieldValue('username', username.toString());
  }, [status_id, username]);

  return (
    <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit}>
      {({ touched }) => (
        <Form className={styles.root}>
          <DialogContainer>
            <div className={styles.body}>
              <Control
                control="select"
                options={[
                  { id: 0, name: 'Новая' },
                  { id: 1, name: 'В процессе' }
                ]}
                name="status_id"
                label="Статус заявки"
                placeholder="Выберите статус..."
                classNameLabel={styles.selectLabel}
              />
              <HSplit />
              <Control
                control="input"
                name="username"
                label="Имя Фамилия"
                placeholder="Введите имя..."
                classNameLabel={styles.selectLabel}
              />
              <div className={styles.contacts}>
                <Control
                  control="input"
                  name="phone"
                  label="Телефон"
                  placeholder="Введите телефон..."
                  classNameLabel={styles.selectLabel}
                  classNameGroup={styles.phone}
                />
                <div className={styles.social}>
                  <Control
                    control="checkbox"
                    name="whatsapp"
                    label="Whatsapp"
                    classNameLabel={styles.selectLabel}
                  />
                  <Control
                    control="checkbox"
                    name="telegram"
                    label="Telegram"
                    classNameLabel={styles.selectLabel}
                  />
                  <Control
                    control="checkbox"
                    name="viber"
                    label="Viber"
                    classNameLabel={styles.selectLabel}
                  />
                </div>
              </div>
              <Control
                control="input"
                name="telegramLogin"
                label="Логин в Telegram"
                placeholder="@username"
                classNameLabel={styles.selectLabel}
              />
              <HSplit />
              <Control
                control="select"
                options={[{ id: 0, name: 'Телеграм сообщество' }]}
                name="source"
                label="Источник"
                classNameLabel={styles.selectLabel}
                classNameGroup={styles.source}
              />
            </div>
          </DialogContainer>
          <DialogFooter
            buttons={props.buttons.map(button => {
              button.disabled = Object.keys(touched).length === 0;
              return button;
            })}
          />
        </Form>
      )}
    </Formik>
  );
};
