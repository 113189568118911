import { useEffect, useRef } from 'react';
import {
  selectAuthToken,
  selectProfile,
  tryUpdateProfile,
  useAppDispatch,
  useSelector
} from 'store';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import {
  AvatarControl,
  Control,
  InlineDialogBody,
  InlineDialogFooter,
  InlineDialogHeader
} from 'stories';
import { YUP_MAX_CHARACTERS, YUP_MIN_CHARACTERS, YUP_REQUIRED } from 'const';

import styles from './ProfileSetup.module.scss';
import { IUpdateProfilePayload } from 'api';
import { Navigate, useNavigate } from 'react-router-dom';

type FormValues = { [x: string]: unknown };

export const ProfileSetup = () => {
  const token = useSelector(selectAuthToken);
  const formikRef = useRef<FormikProps<FormValues>>();
  const { name, lastname } = useSelector(selectProfile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const initialValues = {
    name: '',
    lastname: '',
    login: '',
    photo: null
  };

  // prettier-ignore
  const validationSchema = Yup.object({
    name: Yup.string()
      .required(YUP_REQUIRED('ваше имя'))
      .min(4, YUP_MIN_CHARACTERS(4))
      .max(40, YUP_MAX_CHARACTERS(40)),
    lastname: Yup.string()
      .required(YUP_REQUIRED('вашу фамилию'))
      .min(4, YUP_MIN_CHARACTERS(4))
      .max(40, YUP_MAX_CHARACTERS(40)),
    login: Yup.string()
      .required('Пожалуйста, придумайте логин')
      .min(4, YUP_MIN_CHARACTERS(4))
      .max(40, YUP_MAX_CHARACTERS(40))
  });

  const onSubmit = async values => {
    try {
      await dispatch(tryUpdateProfile(values as IUpdateProfilePayload)).unwrap();

      navigate('/create');
    } catch (e) {
      console.error(e);
    }
  };

  // useEffect(() => {
  //   if (formikRef.current && errors) {
  //     formikRef.current.setErrors(errors);
  //   }
  // }, [errors]);

  useEffect(() => {
    if (name) {
      formikRef.current.setFieldValue('name', name);
    }
  }, [name, lastname]);

  if (!token) {
    return <Navigate to="/" />;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <InlineDialogHeader title="Настройка профиля" />

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ submitForm, isValid, touched }) => (
          <Form>
            <InlineDialogBody>
              <AvatarControl name="photo" className={styles.avatar} />

              <Control
                control="input"
                type="text"
                name="name"
                label="Ваше имя"
                placeholder="Имя..."
              />
              <Control
                control="input"
                type="text"
                name="lastname"
                label="Ваша фамилия"
                placeholder="Фамилия..."
              />
              <Control
                control="input"
                type="text"
                name="login"
                label="login"
                placeholder="@username..."
              />
            </InlineDialogBody>
            <InlineDialogFooter
              isPending={false}
              isValid={isValid}
              isTouched={Object.keys(touched).length > 0}
              submitTitle="Сохранить настройки"
              onSubmitClick={submitForm}
            />
          </Form>
        )}
      </Formik>
    </motion.div>
  );
};
