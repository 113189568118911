import React, { FC, useEffect, useState } from 'react';
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button';
import { useField } from 'formik';
import { Icon, IIconProps } from 'stories';

import cn from 'classnames';
import styles from './FilterControl.module.scss';

import icon_downArrow_20 from 'assets/sprite/20/downArrow_20.svg';

export interface IFilterControlProps {
  icon: IIconProps;
  label: string;
  name: string;
  options: { key: string; value: string }[];
  disabled?: boolean;
  rest?: unknown;
}

export const FilterControl: FC<IFilterControlProps> = props => {
  const { icon, label, name, options = [], disabled = false } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, state, { setValue }] = useField(name);
  const [key, setKey] = useState<string>();

  const handleTitle = () => {
    const option = options.find(opt => opt.value === field.value);
    if (option) {
      setKey(option.key);
    }
  };
  useEffect(handleTitle, [field.value, options]);

  return (
    <div className={styles.control}>
      <Menu>
        {({ isExpanded }) => (
          <React.Fragment>
            <MenuButton className={styles.header} disabled={disabled}>
              <input className={styles.hidden} disabled={disabled} {...field} />
              <Icon className={styles.prefixIcon} viewBox={icon.viewBox} id={icon.id}></Icon>
              <div className={styles.prefix}>{label}:</div>
              <span className={styles.txt}>{key}</span>
              <Icon
                className={cn(styles.arrow, { [styles.isExpanded]: isExpanded })}
                viewBox={icon_downArrow_20.viewBox}
                id={icon_downArrow_20.id}
              ></Icon>
            </MenuButton>
            <MenuList className={styles.body} portal={false}>
              {options.map(option => (
                <MenuItem
                  key={option.value}
                  className={cn(styles.action, {
                    [styles.isSelected]: field.value === option.value
                  })}
                  onSelect={() => setValue(option.value, true)}
                >
                  <span className={styles.actionTtl}>{option.key}</span>
                </MenuItem>
              ))}
            </MenuList>
          </React.Fragment>
        )}
      </Menu>
    </div>
  );
};
