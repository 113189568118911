import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTeamInfo, setTeammateStatus, TeammateDTO, TeamPageDTO } from 'api';
import { handleThunkCondition } from 'store';

interface TeamState extends TeamPageDTO {
  status: 'idle' | 'pending' | 'fulfilled' | 'rejected';
}

const initialState: TeamState = {
  team: [],
  statuses: null,
  help: null,
  status: 'idle'
};

interface TryGetTeamInfoPayload {
  id: number;
}

export const tryGetTeamInfo = createAsyncThunk<TeamPageDTO, TryGetTeamInfoPayload>(
  'project/team',
  async ({ id }, thunkAPI) => {
    try {
      const { data } = await getTeamInfo(id);

      return data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('CHANGE MSG HERE');
    }
  },
  { condition: (payload, { getState }) => handleThunkCondition(getState) }
);

export interface TrySetTeammateStatusPayload {
  teammateId: number;
  status_id: number;
}

export const trySetTeammateStatus = createAsyncThunk<TeammateDTO, TrySetTeammateStatusPayload>(
  'project/team/teammate/set-status',
  async ({ teammateId, status_id }, thunkAPI) => {
    try {
      const { data } = await setTeammateStatus(teammateId, status_id);

      return data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('CHANGE MSG HERE');
    }
  },
  { condition: (payload, { getState }) => handleThunkCondition(getState) }
);

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    resetTeamState() {
      return { ...initialState };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(tryGetTeamInfo.pending, state => {
        state.status = 'pending';
      })
      .addCase(tryGetTeamInfo.rejected, state => {
        state.status = 'rejected';
      })
      .addCase(tryGetTeamInfo.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload,
          status: 'fulfilled'
        };
      });

    builder.addCase(trySetTeammateStatus.fulfilled, (state, { payload }) => {
      const teammate = state.team.find(t => t.id === payload.id);

      teammate.status = { ...payload.status };
    });
  }
});

const { reducer } = teamSlice;

export const { resetTeamState } = teamSlice.actions;
export default reducer;
