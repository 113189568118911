import { NavLink, useLocation } from 'react-router-dom';
import { Icon } from 'stories';
import { motion, AnimateSharedLayout } from 'framer-motion';
import { sidebarNavConfig } from './SidebarNav.config';
import { useSelector } from 'react-redux';
import { selectUserProjects } from 'store';
import cn from 'classnames';

import styles from './SidebarNav.module.scss';

const spring = {
  type: 'spring',
  stiffness: 500,
  damping: 30
};

export const SidebarNav = () => {
  const { selected } = useSelector(selectUserProjects);
  const pathname = useLocation().pathname;

  return (
    <AnimateSharedLayout>
      <div className={styles.nav}>
        {sidebarNavConfig.map((route, index) => {
          const isSelected = `/${selected}/${route.url}` === pathname;

          return (
            <NavLink
              className={({ isActive }) => cn(styles.link, isActive && styles.isSelected)}
              key={index}
              to={`./${route.url}`}
            >
              {isSelected && (
                <motion.div
                  layoutId="outline"
                  className={styles.background}
                  initial={false}
                  transition={spring}
                />
              )}
              <Icon className={styles.icon} viewBox={route.icon.viewBox} id={route.icon.id}></Icon>
              <span className={styles.title}>{route.name}</span>
            </NavLink>
          );
        })}
      </div>
    </AnimateSharedLayout>
  );
};
