import { Field } from 'formik';
import { ErrorHandleCtrl } from './ErrorHandleCtrl';

import cn from 'classnames';
import styles from '../Control.module.scss';

export const InputCtrl = props => {
  const {
    name,
    label,
    placeholder,
    classNameGroup,
    classNameField,
    disabled = false,
    errorField = true,
    ...rest
  } = props;
  return (
    <Field name={name} {...rest}>
      {({ field, meta }) => {
        return (
          <div className={cn(styles.group, classNameGroup)}>
            <label
              className={cn(styles.label, {
                [styles.isGreen]: meta.touched && meta.error === undefined
              })}
              htmlFor={name}
            >
              {label}
            </label>
            <input
              className={cn(styles.field, classNameField)}
              placeholder={placeholder}
              disabled={disabled}
              {...field}
            />
            {errorField && <ErrorHandleCtrl error={meta.error} touched={meta.touched} />}
          </div>
        );
      }}
    </Field>
  );
};
