import { motion } from 'framer-motion';

// import styles from './Login.module.scss';

export const NotFoundPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <h1>Страница не найдена</h1>
    </motion.div>
  );
};
