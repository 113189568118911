import { FC } from 'react';
import { HelpTip, Icon } from 'stories';
import styles from './DialogHeader.module.scss';

import icon_close_20 from 'assets/sprite/20/close_20.svg';
import cn from 'classnames';

interface IDialogHeaderProps {
  onClose: () => void;
  className: string;
  title: string;
}

export const DialogHeader: FC<IDialogHeaderProps> = props => {
  const { onClose, className, title } = props;

  return (
    <div className={cn(styles.header, className)}>
      <div className={styles.container}>
        <h4 className={styles.title}>
          <span>{title}</span>
          <HelpTip />
        </h4>
        <button className={styles.close} onClick={onClose} type="button" tabIndex={-1}>
          <Icon
            className={styles.closeIcon}
            viewBox={icon_close_20.viewBox}
            id={icon_close_20.id}
          ></Icon>
          <span>Закрыть</span>
        </button>
      </div>
    </div>
  );
};
