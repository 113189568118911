import { Icon } from 'stories';

import icon_brand_24 from 'assets/sprite/24/brand_24.svg';

import styles from './Logo.module.scss';

export const Logo = () => (
  <div className={styles.logo}>
    <Icon className={styles.icon} viewBox={icon_brand_24.viewBox} id={icon_brand_24.id}></Icon>
    <span className={styles.description}>Platforma MLM</span>
  </div>
);
