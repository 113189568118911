import { useRef } from 'react';
import { motion } from 'framer-motion';
import { ArticleHeader } from 'modules';
import { Dialog, IDialogType } from 'stories';
import { RefCard } from './components/RefCard';
import { RefCardDialog } from './components/RefCardDialog';
import { useBreakpoints } from 'hooks';

import icon_calendar_20 from 'assets/sprite/20/calendar_20.svg';
import icon_add_20 from 'assets/sprite/24/add_24.svg';
import icon_approve_20 from 'assets/sprite/20/approve_20.svg';
import { ArticleAction } from 'modules/Dashboard/components/ArticleHeader/ArticleAction';

export const ReferralPage = () => {
  const dialogRef = useRef<IDialogType>();
  const { isDesktopMin } = useBreakpoints();

  const openDialog = () => {
    dialogRef.current.open();
  };

  const filters = [
    {
      icon: icon_calendar_20,
      label: 'дата добавления',
      name: 'date',
      disable: true,
      options: [
        { key: 'все', value: 'all' },
        { key: 'не все', value: 'notAll' }
      ]
    }
  ];

  const initialValues = {
    date: filters[0].options[0].value
  };

  const forAction = {
    title: 'Добавить новую ссылку',
    icon: icon_add_20,
    onClick: () => openDialog()
  };

  const data = {
    name: 'Главная рефссылка',
    link: 'https://platforma.com/ref=?234249',
    clicks: '30',
    sales: '12',
    date: '11 Окт 2021',
    source: 'google'
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div>
        <ArticleHeader
          disabled={true}
          title="Партнерка"
          filters={filters}
          forAction={forAction}
          initialValues={initialValues}
        />
      </div>
      <div>
        <RefCard
          name={data.name}
          link={data.link}
          clicks={data.clicks}
          sales={data.sales}
          date={data.date}
          source={data.source}
        />
      </div>
      <Dialog ref={dialogRef} title={'Добавление ссылки'}>
        <RefCardDialog
          buttons={[
            {
              title: 'Сохранить',
              icon: icon_approve_20,
              type: 'submit'
            }
          ]}
        />
      </Dialog>
      {forAction && !isDesktopMin && (
        <ArticleAction title={forAction.title} icon={forAction.icon} onClick={forAction.onClick} />
      )}
    </motion.div>
  );
};
