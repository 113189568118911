import { useBreakpoints } from 'hooks';
import { createContext, FC, useState } from 'react';

interface Props {
  children: React.ReactNode;
}

interface ContextType {
  isActiveVideo: boolean;
  toggle: () => void;
}

export const VideoContext = createContext<ContextType>({
  isActiveVideo: true,
  toggle: () => {}
});

export const VideoProvider: FC<Props> = ({ children }) => {
  const { isTabletMax } = useBreakpoints();
  const [isActiveVideo, setIsActiveVideo] = useState<boolean>(!isTabletMax ? true : false);

  const toggle = () => {
    setIsActiveVideo(!isActiveVideo);
  };

  const value = { isActiveVideo, toggle };
  return <VideoContext.Provider value={value}>{children}</VideoContext.Provider>;
};
