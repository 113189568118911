import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCsrfToken } from 'api';
import axios from 'axios';

type CsrfState = {
  token: string;
  tokenAdded: boolean;
};

const initialState: CsrfState = {
  token: null,
  tokenAdded: false
};

export const tryGetCSRFToken = createAsyncThunk<string, void>(
  'csrf/sanctum',
  async (payload, thunkAPI) => {
    try {
      const { data } = await getCsrfToken();

      return data.csrf;
    } catch (error) {
      // TODO parse message here and pass it rejectWithValue method.
      return thunkAPI.rejectWithValue('CHANGE MSG HERE');
    }
  }
);

const csrfSlice = createSlice({
  name: 'csrf',
  initialState,
  reducers: {
    setDefaultsHeader(state) {
      if (state.token) {
        axios.defaults.headers.post['X-XSRF-TOKEN'] = state.token;
      }
    },
    resetCsrfState() {
      return { ...initialState };
    }
  },
  extraReducers: builder => {
    builder.addCase(tryGetCSRFToken.fulfilled, (state, { payload }) => {
      state.token = payload;
      axios.defaults.headers.post['X-XSRF-TOKEN'] = payload;
    });
  }
});

const { reducer } = csrfSlice;

export const { setDefaultsHeader, resetCsrfState } = csrfSlice.actions;

export default reducer;
