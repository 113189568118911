import { FC } from 'react';
import { Link, To } from 'react-router-dom';
import { Icon } from 'stories';

import cn from 'classnames';
import styles from './DPlate.module.scss';

import icon_question_24 from 'assets/sprite/24/question_24.svg';
import icon_calendar_20 from 'assets/sprite/20/calendar_20.svg';
import icon_downArrow_20 from 'assets/sprite/20/downArrow_20.svg';

interface Props {
  title: string;
  link?: To;
  linkName?: string;
  selectOption?: string;
  children: React.ReactNode;
}

export const DPlate: FC<Props> = ({ title, link, linkName, selectOption, children }) => {
  return (
    <div className={cn(styles.plate)}>
      <div className={styles.header}>
        <span className={styles.title}>{title}</span>
        {/* TODO replace with reference component in future */}
        <span className={styles.reference}>
          <Icon
            className={styles.referenceIcon}
            viewBox={icon_question_24.viewBox}
            id={icon_question_24.id}
          ></Icon>
        </span>
        {selectOption && (
          <div className={styles.drop}>
            <div className={styles.dropHeader}>
              <Icon
                className={styles.dropHeaderPrefixIcon}
                viewBox={icon_calendar_20.viewBox}
                id={icon_calendar_20.id}
              ></Icon>
              <div className={styles.dropHeaderPrefix}>период:</div>
              <span>{selectOption}</span>
              <Icon
                className={styles.dropHeaderArrow}
                viewBox={icon_downArrow_20.viewBox}
                id={icon_downArrow_20.id}
              ></Icon>
            </div>
          </div>
        )}
      </div>
      <div className={styles.body}>{children}</div>
      {link && linkName && (
        <Link className={styles.link} to={link}>
          {linkName}
        </Link>
      )}
    </div>
  );
};
