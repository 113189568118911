import { Form, Formik, FormikProps } from 'formik';
import { Control, Icon, InlineDialogBody, InlineDialogFooter, InlineDialogHeader } from 'stories';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import { YUP_REQUIRED } from 'const';
import { tryResetPassword } from 'store/modules/authSlice';
import { selectAuth, useAppDispatch, useSelector } from 'store';
import { useEffect, useRef, useState } from 'react';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import styles from './Login.module.scss';

import icon_danger_24 from 'assets/sprite/24/danger_24.svg';

import img_resetLinkSended from 'assets/images/auth/reset-link-sended.svg';

type FormValues = { [x: string]: unknown };

export const ResetPassword = () => {
  const formikRef = useRef<FormikProps<FormValues>>();
  const { errors, status } = useSelector(selectAuth);
  const dispatch = useAppDispatch();
  const [sent, setSent] = useState(false);

  const initialValues = {
    email: ''
  };

  // prettier-ignore
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(YUP_REQUIRED('валидный е-мейл'))
      .required(YUP_REQUIRED('е-мейл')),
  });

  const onSubmit = async values => {
    const data = {
      ...values
    };

    const { payload } = await dispatch(tryResetPassword(data));
    if (payload) {
      setSent(true);
    }
  };

  useEffect(() => {
    if (formikRef.current && errors) {
      formikRef.current.setErrors(errors);
    }
  }, [errors]);

  if (sent) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <InlineDialogHeader title="Сообщение отправлено" />
        <div className={styles.alert}>
          <div className={styles.message}>
            <Icon
              className={styles.messageIcon}
              viewBox={icon_danger_24.viewBox}
              id={icon_danger_24.id}
            ></Icon>
            <span className={styles.messageTxt}>Проверьте вашу почту</span>
          </div>
          <div className={styles.preview}>
            <img src={img_resetLinkSended} alt="reset link sended" />
          </div>
        </div>
      </motion.div>
    );
  }

  return (
    // <GoogleReCaptchaProvider reCaptchaKey="[Your recaptcha key]" language="ru">
    // </GoogleReCaptchaProvider>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <InlineDialogHeader title="Восстановление пароля" />

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ submitForm, isValid, touched }) => (
          <Form>
            <InlineDialogBody>
              <Control
                control="input"
                type="email"
                name="email"
                label="Email"
                placeholder="Ваш email..."
              />
              <div className={styles.helpText}>На ваш email придет письмо с инструкциями</div>
            </InlineDialogBody>
            <InlineDialogFooter
              isPending={status === 'pending'}
              isValid={isValid}
              isTouched={Object.keys(touched).length > 0}
              submitTitle="Отправить"
              onSubmitClick={submitForm}
            />
          </Form>
        )}
      </Formik>
    </motion.div>
  );
};
