import { InputCtrl, TextareaCtrl, SelectCtrl, RadioBtnsCtrl, Checkboxes } from './components';

export const Control = props => {
  const { control, ...rest } = props;

  switch (control) {
    case 'textArea':
      return <TextareaCtrl {...rest} />;
    case 'select':
      return <SelectCtrl {...rest} />;
    case 'radio':
      return <RadioBtnsCtrl {...rest} />;
    case 'checkbox':
      return <Checkboxes {...rest} />;
    case 'input':
    default:
      return <InputCtrl {...rest} />;
  }
};
