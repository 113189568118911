import { Formik, Form, FormikProps } from 'formik';
import { FC, useEffect, useRef } from 'react';
import { DialogContainer } from 'stories';
import { DialogFooter, IDialogFooterButton } from 'stories/Dialog/shared/DialogFooter';

import styles from './CourseCreateDialog.module.scss';

// import { HSplit } from 'stories/HSplit';

type FormValues = { [x: string]: unknown };

interface IListCardDialogProps {
  id?: string;
  username?: string;
  status_id?: number;
  newForm?: boolean;
  buttons: IDialogFooterButton[];
}

export const CourseCreateDialog: FC<IListCardDialogProps> = props => {
  const { username, status_id } = props;

  const formikRef = useRef<FormikProps<FormValues>>();

  const initialValues = {
    status_id: '',
    username: '',
    phone: '',
    whatsapp: false,
    telegram: false,
    viber: false,
    telegramLogin: '',
    source: ''
  };

  const onSubmit = async (values: typeof initialValues) => {
    console.log(values);
  };

  useEffect(() => {
    if (!username && !status_id) return;
    formikRef.current.setFieldValue('status_id', status_id.toString());
    formikRef.current.setFieldValue('username', username.toString());
  }, [status_id, username]);

  return (
    <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit}>
      {({ touched }) => (
        <Form className={styles.root}>
          <DialogContainer>
            <div className={styles.body}></div>
          </DialogContainer>
          <DialogFooter
            buttons={props.buttons.map(button => {
              button.disabled = Object.keys(touched).length === 0;
              return button;
            })}
          />
        </Form>
      )}
    </Formik>
  );
};
