import { Icon } from 'stories';
import styles from './RatingStars.module.scss';
import icon_star_filled_20 from 'assets/sprite/20/starFilled_20.svg';
import icon_star_half_filled from 'assets/sprite/20/starHalfFilled_20.svg';
import { FC } from 'react';
import classnames from 'classnames';

interface Props {
  isStarsVisible?: boolean;
  rating: number;
  className?: string;
  isSmall?: boolean;
}

export const RatingStars: FC<Props> = ({
  isStarsVisible = true,
  isSmall = false,
  rating = 0,
  className
}) => {
  const fixedRating = rating.toFixed(1);
  const starsArr = Array.from({ length: Number(fixedRating) }, (_, i) => i + 1);
  const numberAfterDot = rating.toString().slice(2, 3);
  const isMoreThanFive = Number(numberAfterDot) >= 5;

  return (
    <div className={classnames(styles.courseRating, className)}>
      <div className={classnames(styles.starCount, isSmall ? styles.small : '')}>
        <span>{rating.toFixed(1)}</span>
      </div>
      {isStarsVisible && (
        <div className={styles.stars}>
          {starsArr.map(item => {
            return (
              <Icon
                viewBox={icon_star_filled_20.viewBox}
                id={icon_star_filled_20.id}
                className={styles.star}
                key={item}
              ></Icon>
            );
          })}
          {isMoreThanFive && (
            <Icon viewBox={icon_star_half_filled.viewBox} id={icon_star_half_filled.id}></Icon>
          )}
        </div>
      )}
    </div>
  );
};
