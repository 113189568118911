import cn from 'classnames';
import { FC, memo } from 'react';

import styles from './Avatar.module.scss';

const isLightColor = color => {
  const c = color.substring(1); // strip #
  const rgb = parseInt(c, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  return luma > 128;
};

const stringToColour = str => {
  const word = str.length < 2 ? str + 'ABCDFE' : str;

  let hash = 0;
  for (let i = 0; i < word.length; i++) {
    hash = word.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += '' + value.toString(16);
  }
  return { color: colour, isLight: isLightColor(colour) };
};

interface IAvatarProps {
  photo: string;
  size?: number;
  firstName?: string;
  lastName?: string;
  short_name?: string;
  isSquare?: boolean;
  className?: string;
}

const prepareLetters = (firstName: string, lastName: string, short_name?: string) => {
  if (short_name) {
    const firstLetter = short_name?.charAt(0).toUpperCase() ?? '';
    const lastLetter = short_name?.charAt(short_name.length - 1).toUpperCase() ?? '';

    return { firstLetter, lastLetter };
  }

  const firstLetter = firstName?.charAt(0).toUpperCase() ?? '';
  const lastLetter = lastName?.charAt(0).toUpperCase() ?? '';

  return { firstLetter, lastLetter };
};

export const Avatar: FC<IAvatarProps> = memo(props => {
  const {
    size = 100,
    firstName = '',
    lastName = '',
    short_name = '',
    isSquare = false,
    className
  } = props;

  const viewBox = `0 0 ${size} ${size}`;

  const superShortName = short_name.length === 1 ? short_name + short_name : short_name;

  const { firstLetter, lastLetter } = prepareLetters(firstName, lastName, superShortName);

  const initials = firstLetter + lastLetter;

  const { color, isLight } = stringToColour(
    (superShortName ? superShortName : firstName) + firstName
  );

  return (
    <svg
      className={cn(
        styles.avatar,
        isLight && styles.isLight,
        isSquare && styles.isSquare,
        className
      )}
      width={size}
      height={size}
      viewBox={viewBox}
      style={{ backgroundColor: color }}
    >
      <text
        className={styles.text}
        fill="currentColor"
        x="50%"
        y="50%"
        cx={size / 2}
        cy={size / 2}
        r={size / 2}
        textAnchor="middle"
        alignmentBaseline="middle"
        fontWeight="normal"
        dy=".1em"
        dominantBaseline="middle"
      >
        {initials}
      </text>
    </svg>
  );
});
