import axios from 'axios';
import toast from 'react-hot-toast';
import { store, tryLogout } from 'store';
import { axiosErrorHandler } from 'utils';
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  config => {
    config.baseURL = process.env.REACT_APP_API_ENDPOINT;

    const token = store.getState()?.auth?.token,
      localRequest = config.url.indexOf('https://');

    if (token && localRequest) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log('error.response:', error.response.status);
    if (error.response.status === 401) {
      store.dispatch(tryLogout());
      toast.error('Ваша сессия истекла');
    }
    Promise.reject(axiosErrorHandler(error));
  }
);
