import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  ErrorsFormikResponse,
  FindUserReferralDTO,
  getInviterInfo,
  login,
  register,
  resetPassword
} from 'api';
import {
  handleThunkCondition,
  PlainActionCasesType,
  resetConfigState,
  resetCsrfState,
  resetDashboardState,
  resetProjectsState,
  resetTeamState,
  resetUserState,
  StoreValidationErrorsType
} from 'store';
import { axiosErrorHandler } from 'utils';

type AuthState = {
  token: string;
  refer_key: string;
  referer: FindUserReferralDTO;
  errors: StoreValidationErrorsType;
  status: PlainActionCasesType;
};

const initialState: AuthState = {
  token: null,
  refer_key: null,
  referer: null,
  errors: null,
  status: 'idle'
};

interface TryRegisterPayload {
  name: string;
  email: string;
  refer_key?: string;
  password: string;
  password_confirmation: string;
}

export const tryRegister = createAsyncThunk<string, TryRegisterPayload>(
  'auth/register',
  async ({ name, email, refer_key, password, password_confirmation }, thunkAPI) => {
    try {
      // await thunkAPI.dispatch(tryGetCSRFToken());

      const { data } = await register({
        name,
        email,
        refer_key,
        password,
        password_confirmation
      });

      // setTimeout(() => {
      //   thunkAPI.dispatch(tryGetCSRFToken());
      // }, 500);

      return data.token;
    } catch (e) {
      const error = axiosErrorHandler(e);

      if (error.type === 'axios-validation-error') {
        return thunkAPI.rejectWithValue(error.error.errors);
      }

      return thunkAPI.rejectWithValue('');
    }
  },
  { condition: (payload, { getState }) => handleThunkCondition(getState) }
);

export const tryResetPassword = createAsyncThunk<boolean, TryRegisterPayload>(
  'auth/reset-password',
  async ({ email }, thunkAPI) => {
    try {
      await resetPassword({
        email
      });

      return true;
    } catch (e) {
      const error = axiosErrorHandler(e);

      if (error.type === 'axios-validation-error') {
        return thunkAPI.rejectWithValue(error.error.errors);
      }

      return thunkAPI.rejectWithValue(null);
    }
  },
  { condition: (payload, { getState }) => handleThunkCondition(getState) }
);

interface TryLoginPayload {
  email: string;
  password: string;
}

export const tryLogin = createAsyncThunk<string, TryLoginPayload>(
  'auth/login',
  async ({ email, password }, thunkAPI) => {
    try {
      const { data } = await login({ email, password });
      return data.token;
    } catch (e) {
      const error = axiosErrorHandler(e);

      if (error.type === 'axios-validation-error') {
        return thunkAPI.rejectWithValue(error.error.errors);
      }

      return thunkAPI.rejectWithValue('');
    }
  },
  { condition: (payload, { getState }) => handleThunkCondition(getState) }
);

export const tryLogout = createAsyncThunk<boolean, void>(
  'auth/logout',
  async (payload, thunkAPI) => {
    try {
      // await logOut();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      // thunkAPI.dispatch(resetAuthState())
      thunkAPI.dispatch(resetDashboardState());
      thunkAPI.dispatch(resetTeamState());
      thunkAPI.dispatch(resetConfigState());
      thunkAPI.dispatch(resetCsrfState());
      thunkAPI.dispatch(resetProjectsState());
      thunkAPI.dispatch(resetUserState());

      return true;
    } catch (error) {
      // TODO parse message here and pass it rejectWithValue method.
      return thunkAPI.rejectWithValue('CHANGE MSG HERE');
    }
  },
  { condition: (payload, { getState }) => handleThunkCondition(getState) }
);

interface TryGetUserByReferredKeyPayload {
  refer_key: string;
}

export const tryGetUserByReferredKey = createAsyncThunk<
  FindUserReferralDTO,
  TryGetUserByReferredKeyPayload
>('auth/getInviterInfo', async ({ refer_key }, thunkAPI) => {
  try {
    const { data } = await getInviterInfo(refer_key);

    return Array.isArray(data) ? null : (data as { data: FindUserReferralDTO }).data;
  } catch (error) {
    // TODO parse message here and pass it rejectWithValue method.
    return thunkAPI.rejectWithValue(null);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setReferKey(state, { payload }: PayloadAction<string>) {
      state.refer_key = payload;
    },
    resetAuthState() {
      return { ...initialState };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(tryRegister.pending, state => {
        state.status = 'pending';
      })
      .addCase(tryRegister.rejected, (state, { payload }) => {
        state.errors = payload as ErrorsFormikResponse;
        state.status = 'rejected';
        state.token = null;
      })
      .addCase(tryRegister.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.token = action.payload;
      });

    builder
      .addCase(tryLogin.pending, state => {
        state.status = 'pending';
      })
      .addCase(tryLogin.rejected, (state, { payload }) => {
        state.errors = payload as ErrorsFormikResponse;
        state.status = 'rejected';
        state.token = null;
      })
      .addCase(tryLogin.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.token = action.payload;
      });

    builder
      .addCase(tryResetPassword.pending, state => {
        state.status = 'pending';
      })
      .addCase(tryResetPassword.rejected, (state, { payload }) => {
        state.errors = payload as ErrorsFormikResponse;
        state.status = 'rejected';
      })
      .addCase(tryResetPassword.fulfilled, state => {
        state.status = 'fulfilled';
      });

    builder.addCase(tryLogout?.fulfilled, state => {
      return {
        ...initialState,
        refer_key: state.refer_key
      };
    });

    builder
      .addCase(tryGetUserByReferredKey.pending, (state, { meta }) => {
        state.status = 'pending';
        state.refer_key = meta.arg.refer_key;
      })
      .addCase(tryGetUserByReferredKey.fulfilled, (state, { payload }) => {
        if (!payload) {
          state.status = 'rejected';
          state.refer_key = null;
        }

        state.status = 'fulfilled';
        state.referer = payload;
      })
      .addCase(tryGetUserByReferredKey.rejected, state => {
        state.status = 'rejected';
        state.refer_key = null;
      });
  }
});

const { reducer } = authSlice;

export const { setReferKey, resetAuthState } = authSlice.actions;
export default reducer;
