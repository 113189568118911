import axios from 'axios';

// * Login
export interface ILoginPayloadAPI {
  email: string;
  password: string;
}
export interface ILoginResponseAPI {
  token: string;
}
export const login = async (data: ILoginPayloadAPI) => {
  return axios.post<ILoginResponseAPI>('/login', data);
};

// * Logout
export const logOut = async () => {
  return axios.post<void>('/logout');
};

// * Register
export interface IRegisterPayloadAPI {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  refer_key?: string;
}
export interface IRegisterResponseAPI {
  token: string;
}
export const register = async (data: IRegisterPayloadAPI) => {
  return axios.post<IRegisterResponseAPI>('/register', data);
};

export interface FindUserReferralDTO {
  lastname: string;
  name: string;
  photo: string;
}
export const getInviterInfo = async (refer_key: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return axios.get<{ data: FindUserReferralDTO } | []>(`/api/user_referral/find/${refer_key}`);
};
export interface IResetPasswordPayloadAPI {
  email: string;
}
export interface IResetPasswordResponseAPI {
  data: [];
  message: string;
}
export const resetPassword = async (data: IResetPasswordPayloadAPI) => {
  return axios.post<IResetPasswordResponseAPI>('/api/password/forgot-password', data);
};
