import { motion } from 'framer-motion';
import { useBreakpoints } from 'hooks';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { tryGetCourse } from 'store/modules/courseSlice';
import { selectCourse } from 'store/selectors/course';
import { CourseInfoSidebar } from './components/CourseInfoSidebar';
import { VideoBlock } from './components/VideoBlock';
import { VideoProvider } from './contexts/VideoContext';
import styles from './CoursePage.module.scss';

export const CoursePage = () => {
  const { status } = useSelector(selectCourse);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isDesktopMin } = useBreakpoints();

  const getCourse = async () => {
    try {
      const payload = {
        education_id: Number(params.education_id),
        video_id: params.video_id ? Number(params.video_id) : null,
        setDefaultVideo: isDesktopMin
      };

      const { video_id } = await dispatch(tryGetCourse(payload)).unwrap();

      // TODO create VideoIdGuard based on this scenario
      if (!params.video_id && isDesktopMin) {
        navigate(`${video_id}`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getCourse();
  }, [dispatch]);

  if (status == 'loading') {
    return <div>Loading</div>;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <VideoProvider>
        <div className={styles.coursePage}>
          <VideoBlock />
          <CourseInfoSidebar />
        </div>
      </VideoProvider>
    </motion.div>
  );
};
