import { Link } from 'react-router-dom';
import { Avatar, Dialog, Icon, IDialogType } from 'stories';
import { ProfileDrop } from './components/ProfileDrop';
import { NotificationDialog } from './components/NotificationsDialog';
import { memo, useRef } from 'react';
import { selectUserProjects, useSelector } from 'store';
import cn from 'classnames';
import { useBreakpoints } from 'hooks';

import styles from './Header.module.scss';

import icon_brand_24 from 'assets/sprite/24/brand_24.svg';
import icon_inbox_24 from 'assets/sprite/24/inbox_24.svg';
import icon_notificationActive_24 from 'assets/sprite/24/notificationActive_24.svg';
import calender_24 from 'assets/sprite/24/calender_24.svg';

export const Header = memo(() => {
  const { selected, projects } = useSelector(selectUserProjects);
  const selectedProject = projects.find(p => p.id === selected);

  const dialogRef = useRef<IDialogType>();

  const openDialog = () => {
    dialogRef.current.open();
  };

  const { isTabletMax } = useBreakpoints();

  return (
    <header className={styles.header}>
      {!isTabletMax && (
        <div className={styles.logo}>
          {selectedProject.id === 1 ? (
            <div className={styles.logoBrand}>
              <Icon
                className={styles.logoBrandIcon}
                viewBox={icon_brand_24.viewBox}
                id={icon_brand_24.id}
              ></Icon>
            </div>
          ) : (
            <Avatar
              className={styles.avatar}
              isSquare={true}
              photo={selectedProject.photo}
              firstName={selectedProject.name}
              lastName={selectedProject.name.slice(1, selectedProject.name.length - 1)}
            />
          )}
          <span className={styles.logoDescription}>
            {selectedProject.id === 1 ? 'Platforma MLM' : selectedProject.name}
          </span>
        </div>
      )}

      <div className={styles.navigation}>
        <Link className={styles.link} to="/">
          <Icon
            className={styles.linkIcon}
            viewBox={icon_inbox_24.viewBox}
            id={icon_inbox_24.id}
          ></Icon>
          <span className={styles.linkDesc}>Новости</span>
        </Link>
        <button
          type="button"
          onClick={e => {
            e.preventDefault();
            openDialog();
          }}
          className={cn(styles.link, styles.button)}
        >
          <Icon
            className={styles.linkIcon}
            viewBox={icon_notificationActive_24.viewBox}
            id={icon_notificationActive_24.id}
          ></Icon>
          <span className={styles.linkDesc}>Уведомления</span>
        </button>
        <Link className={styles.link} to="/">
          <Icon
            className={styles.linkIcon}
            viewBox={calender_24.viewBox}
            id={calender_24.id}
          ></Icon>
          <span className={styles.linkDesc}>События</span>
        </Link>
      </div>
      {!isTabletMax && (
        <div className={styles.dropdown}>
          <ProfileDrop />
        </div>
      )}
      <Dialog ref={dialogRef} title={'Уведомления'}>
        <NotificationDialog />
      </Dialog>
    </header>
  );
});
