import { Formik, Form, FormikProps } from 'formik';
import { FC, useEffect, useRef } from 'react';
import { Avatar, Control, DialogContainer } from 'stories';
import { DialogFooter } from 'stories/Dialog/shared/DialogFooter';
import {
  selectConfigTeammateStatuses,
  TrySetTeammateStatusPayload,
  trySetTeammateStatus,
  useAppDispatch,
  useSelector
} from 'store';

import styles from './TeammateDialog.module.scss';

import icon_approve_20 from 'assets/sprite/20/approve_20.svg';

type FormValues = { [x: string]: unknown };

interface ITeammateDialogProps {
  id: number;
  firstName: string;
  lastName: string;
  photo: string;
  status_id: number;
}

export const TeammateDialog: FC<ITeammateDialogProps> = props => {
  const { id, firstName, lastName, photo, status_id } = props;
  const dispatch = useAppDispatch();
  const formikRef = useRef<FormikProps<FormValues>>();
  const statuses = useSelector(selectConfigTeammateStatuses);

  const initialValues = {
    status_id: ''
  };

  const onSubmit = async (values: typeof initialValues) => {
    const payload: TrySetTeammateStatusPayload = {
      teammateId: id,
      status_id: parseInt(values.status_id, 10)
    };
    await dispatch(trySetTeammateStatus(payload));
  };

  useEffect(() => {
    if (status_id) {
      formikRef.current.setFieldValue('status_id', status_id.toString());
    }
  }, [status_id]);

  return (
    <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit}>
      {({ touched }) => (
        <Form className={styles.root}>
          <DialogContainer>
            <div className={styles.body}>
              <div className={styles.user}>
                <Avatar
                  photo={photo}
                  firstName={firstName}
                  lastName={lastName}
                  className={styles.avatar}
                  size={100}
                />
                <div className={styles.name}>
                  {firstName} {lastName}
                </div>
              </div>
              <Control
                control="select"
                options={statuses}
                name="status_id"
                label="Статус партнера"
                placeholder="Выберите статус..."
                classNameLabel={styles.selectLabel}
              />
            </div>
          </DialogContainer>
          <DialogFooter
            buttons={[
              {
                title: 'Сохранить',
                icon: icon_approve_20,
                type: 'submit',
                disabled: Object.keys(touched).length === 0
              }
            ]}
          />
        </Form>
      )}
    </Formik>
  );
};
