import { Form, Formik } from 'formik';
import { Control } from 'stories';
import { SettingItem } from '../Settingitem/SettingItem';

export const GeneralSettings = () => {
  const initialValues = {
    projectName: '',
    projectShortName: ''
  };

  const onSubmit = async values => {
    console.log(values);
  };

  return (
    <SettingItem title="Общие настройки" desc="Название и короткое имя проекта">
      <Formik initialValues={initialValues} onSubmit={values => onSubmit(values)}>
        {() => (
          <Form>
            <Control
              control="input"
              type="text"
              name="projectName"
              label="Название проекта"
              placeholder={'Platforma MLM'}
            />
            <Control
              control="input"
              type="text"
              name="projectShortName"
              label="Короткое имя проекта"
              placeholder={'@platforma'}
            />
          </Form>
        )}
      </Formik>
    </SettingItem>
  );
};
