import { FC } from 'react';

import styles from './InlineDialogBody.module.scss';

interface InlineDialogBodyProps {
  children: React.ReactNode;
}

export const InlineDialogBody: FC<InlineDialogBodyProps> = ({ children }) => (
  <div className={styles.body}>{children}</div>
);
