import React from 'react';
import { Icon } from 'stories';

import icon_telegram from 'assets/sprite/oval-socials/os_telegram.svg';
import icon_viber from 'assets/sprite/oval-socials/os_viber.svg';
import icon_whatsapp from 'assets/sprite/oval-socials/os_whatsapp.svg';
import cn from 'classnames';
import styles from './SocialBar.module.scss';

export const SocialBar = () => {
  return (
    <div className={styles.socials}>
      <div className={cn(styles.soc, { [styles.isTelegram]: true })}>
        <Icon
          className={styles.socIcon}
          viewBox={icon_telegram.viewBox}
          id={icon_telegram.id}
        ></Icon>
      </div>
      <div className={cn(styles.soc, { [styles.isViber]: true })}>
        <Icon className={styles.socIcon} viewBox={icon_viber.viewBox} id={icon_viber.id}></Icon>
      </div>
      <div className={cn(styles.soc, { [styles.isWhatsapp]: false })}>
        <Icon
          className={styles.socIcon}
          viewBox={icon_whatsapp.viewBox}
          id={icon_whatsapp.id}
        ></Icon>
      </div>
    </div>
  );
};
