import { SidebarNav } from './SidebarNav';
import { SidebarProjects } from './SidebarProjects';
import { ProfileDrop } from '../Header/components/ProfileDrop';
import { Icon } from 'stories';
import { Link } from 'react-router-dom';
import { useBreakpoints } from 'hooks';

import styles from './Sidebar.module.scss';

import icon_brand_24 from 'assets/sprite/24/brand_24.svg';

export const Sidebar = () => {
  const { isTabletMax } = useBreakpoints();

  return (
    <div className={styles.sidebar}>
      <div className={styles.projects}>
        <Link className={styles.logo} to="/">
          <Icon
            className={styles.logoIcon}
            viewBox={icon_brand_24.viewBox}
            id={icon_brand_24.id}
          ></Icon>
        </Link>
        <div className={styles.inner}>
          <SidebarProjects />
        </div>
        {isTabletMax && <ProfileDrop />}
      </div>
      {!isTabletMax && <SidebarNav />}
    </div>
  );
};
