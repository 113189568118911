import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ErrorsFormikResponse,
  getProfile,
  IUpdateProfilePayload,
  ProfileDTO,
  updateProfile
} from 'api';
import { PlainActionCasesType, StoreValidationErrorsType } from 'store';
import { axiosErrorHandler } from 'utils';

export type UserState = {
  profile: ProfileDTO;
  errors: StoreValidationErrorsType;
  status: PlainActionCasesType;
};

const initialState: UserState = {
  profile: null,
  errors: null,
  status: 'idle'
};

export const tryGetProfile = createAsyncThunk<ProfileDTO, void>(
  'user/info',
  async (payload, thunkAPI) => {
    try {
      const { data } = await getProfile();

      return data.data;
    } catch (error) {
      // TODO parse message here and pass it rejectWithValue method.
      return thunkAPI.rejectWithValue('CHANGE MSG HERE');
    }
  }
);

export const tryUpdateProfile = createAsyncThunk<ProfileDTO, IUpdateProfilePayload>(
  'user/info/update',
  async (payload, thunkAPI) => {
    try {
      // await thunkAPI.dispatch(tryGetCSRFToken());

      const { data } = await updateProfile(payload);

      return data.data;
    } catch (e) {
      const error = axiosErrorHandler(e);

      if (error.type === 'axios-validation-error') {
        return thunkAPI.rejectWithValue(error.error.errors);
      }

      return thunkAPI.rejectWithValue('');
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserState() {
      return { ...initialState };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(tryGetProfile.pending, state => {
        state.status = 'pending';
      })
      .addCase(tryGetProfile.rejected, state => {
        state.profile = null;
        state.status = 'rejected';
      })
      .addCase(tryGetProfile.fulfilled, (state, { payload }) => {
        state.profile = payload;
        state.status = 'fulfilled';
      });

    builder
      .addCase(tryUpdateProfile.pending, state => {
        state.status = 'pending';
      })
      .addCase(tryUpdateProfile.rejected, (state, { payload }) => {
        state.errors = payload as ErrorsFormikResponse;
        state.status = 'rejected';
      })
      .addCase(tryUpdateProfile.fulfilled, (state, { payload }) => {
        state.profile = payload;
        state.status = 'fulfilled';
      });
  }
});

export const { resetUserState } = userSlice.actions;

const { reducer } = userSlice;

export default reducer;
