import { FC } from 'react';
import { Logo } from './components';

import styles from './InlineDialog.module.scss';

interface InlineDialogProps {
  children: React.ReactNode;
}

export const InlineDialog: FC<InlineDialogProps> = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.inner}>
      <div className={styles.header}>
        <Logo />
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  </div>
);
