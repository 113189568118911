import { motion } from 'framer-motion';
import { ArticleHeader } from 'modules';
import { CourseList } from './components/CourseList';
import icon_info_20 from 'assets/sprite/20/info_20.svg';
import icon_calendar_20 from 'assets/sprite/20/calendar_20.svg';
import icon_source_20 from 'assets/sprite/20/source_20.svg';
import icon_add_20 from 'assets/sprite/24/add_24.svg';
import icon_approve_20 from 'assets/sprite/20/approve_20.svg';
import { useEffect, useRef } from 'react';
import { Dialog, IDialogType } from 'stories';
import { CourseCreateDialog } from './components/CourseCreateDialog';
import { selectProfile, useAppDispatch } from 'store';
import { tryGetEducation } from 'store/modules/educationSlice';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

export const EducationPage = () => {
  const { id: userId } = useSelector(selectProfile);
  const dispatch = useAppDispatch();
  const dialogRef = useRef<IDialogType>();
  const [searchParams] = useSearchParams();
  const queryStatus = searchParams.get('status');
  const queryDate = searchParams.get('date');
  const querySource = searchParams.get('source');

  const filters = [
    {
      icon: icon_info_20,
      label: 'Статус',
      name: 'status',
      options: [
        { key: 'все', value: 'all' },
        { key: 'не все', value: 'notAll' }
      ]
    },
    {
      icon: icon_calendar_20,
      label: 'дата добавления',
      name: 'date',
      options: [
        { key: 'все', value: 'all' },
        { key: 'не все', value: 'notAll' }
      ]
    },
    {
      icon: icon_source_20,
      label: 'Источник',
      name: 'source',
      options: [
        { key: 'все', value: 'all' },
        { key: 'не все', value: 'notAll' }
      ]
    }
  ];

  const initialValues = {
    status: queryStatus || filters[0].options[0].value,
    date: queryDate || filters[1].options[0].value,
    source: querySource || filters[2].options[0].value
  };

  const openDialog = () => {
    dialogRef.current.open();
  };

  const forAction = {
    title: 'Добавить новый курс',
    icon: icon_add_20,
    onClick: () => openDialog()
  };

  useEffect(() => {
    dispatch(tryGetEducation({ userId, searchParams }));
  }, [searchParams]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <ArticleHeader
        title="Обучение"
        filters={filters}
        initialValues={initialValues}
        // disabled={true}
        forAction={forAction}
      />
      <CourseList />
      <Dialog ref={dialogRef} title={'Добавление курса'}>
        <CourseCreateDialog
          buttons={[
            {
              title: 'Сохранить',
              icon: icon_approve_20,
              type: 'submit'
            }
          ]}
        />
      </Dialog>
    </motion.div>
  );
};
