import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import icon_down from 'assets/sprite/20/downArrow_20.svg';

import styles from './ProgressBar.module.scss';
import { Icon } from 'stories';

const getStatus = (status: string) => {
  switch (status) {
    case 'new':
      return { color: '#1D63CB', status: 'Новий', percent: 25 };
    case 'processing':
      return { color: '#FABE66', status: 'В процессе', percent: 50 };
    case 'inTeam':
      return { color: '#2CB742', status: 'В команде', percent: 100 };
    case 'refusal':
      return { color: '#FF6161', status: 'Отказ', percent: 100 };
    default:
      return { color: '#FF6161', status: 'Отсутствует', percent: 0 };
  }
};

interface ProgressBarProps {
  status: string;
}

export const ProgressBar: FC<ProgressBarProps> = ({ status }) => {
  const [statusTitle, setStatusTitle] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);
  const [colorProgress, setColorProgress] = useState<string>('');
  const [isActiveStatus, setActiveStatus] = useState<boolean>(false);

  useEffect(() => {
    const { color, percent, status: statusIndicator } = getStatus(status);
    setColorProgress(color);
    setProgress(percent);
    setStatusTitle(statusIndicator);
  }, []);

  return (
    <div className={styles.progressBar}>
      <div className={styles.progressBarAndArrow}>
        <div className={styles.progressBarField}>
          <span
            className={styles.progressIndicator}
            style={{ width: `${progress}%`, backgroundColor: colorProgress }}
          ></span>
        </div>
        <p className={styles.progressStatus}>{statusTitle}</p>
      </div>
      <div className={styles.dropDownStatus}>
        <button onClick={() => setActiveStatus(state => !state)} className={styles.arrowBtn}>
          <Icon className={styles.arrow} viewBox={icon_down.viewBox} id={icon_down.id}></Icon>
        </button>
        <div className={cn(styles.dropDownContent, { [styles.hidden]: !isActiveStatus })}>
          <p className={styles.item}></p>
        </div>
      </div>
    </div>
  );
};
