import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CourseDTO, getCourse, VideoDTO } from 'api';
import { StoreValidationErrorsType } from 'store';

type CourseState = {
  activeCourse: CourseDTO;
  activeVideoIndex: number;
  activeVideo: VideoDTO;
  errors: StoreValidationErrorsType;
  status: 'loading' | 'ready';
};

const initialState: CourseState = {
  activeCourse: null,
  activeVideo: null,
  activeVideoIndex: null,
  errors: null,
  status: 'loading'
};

export const tryGetCourse = createAsyncThunk<
  { data: CourseDTO; video_id: number; setDefaultVideo: boolean },
  { education_id: number; setDefaultVideo: boolean; video_id?: number }
>('user/course', async (payload, thunkAPI) => {
  const { education_id, video_id, setDefaultVideo } = payload;

  try {
    const {
      data: { data }
    } = await getCourse(education_id);

    const videoId = video_id ?? data?.videos[0].id;

    return { data, video_id: videoId, setDefaultVideo };
  } catch (error) {
    // TODO parse message here and pass it rejectWithValue method.
    return thunkAPI.rejectWithValue('CHANGE MSG HERE');
  }
});

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    resetCourseState() {
      return { ...initialState };
    },
    setActiveVideoIndex(state, { payload }: PayloadAction<number>) {
      state.activeVideoIndex = payload;
    },
    unlockVideo(state, { payload }: PayloadAction<number>) {
      const videos = state.activeCourse.videos;
      const updatedVideos = videos.map(item => {
        if (item.id == payload) {
          item.locked = false;
        }
        return item;
      });
      state.activeCourse.videos = [...updatedVideos];
    },
    setIsVideoWatched(state, { payload }: PayloadAction<number>) {
      const watchedVideoCount = state.activeCourse.watched_video_count;
      const videos = state.activeCourse.videos;
      const updatedVideos = videos.map(item => {
        if (item.id == payload) {
          item.watched = true;
        }
        return item;
      });
      state.activeCourse.videos = [...updatedVideos];

      state.activeCourse = {
        ...state.activeCourse,
        watched_video_count: watchedVideoCount + 1
      };
    }
  },
  extraReducers: builder => {
    builder.addCase(tryGetCourse.fulfilled, (state, { payload }) => {
      const course = { ...payload.data };

      // set default video
      const videoIndex = course.videos.findIndex(video => video.id == Number(payload.video_id));

      course.videos[videoIndex].locked = false;

      if (payload.setDefaultVideo) {
        state.activeVideo = course?.videos[videoIndex];
        state.activeVideoIndex = videoIndex;
      }

      state.activeCourse = course;

      state.status = 'ready';
    });
  }
});

export const { setActiveVideoIndex, resetCourseState, setIsVideoWatched, unlockVideo } =
  courseSlice.actions;
const { reducer } = courseSlice;

export default reducer;
