import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { selectAuth, useAppDispatch, useSelector } from 'store';
import { tryGetUserByReferredKey } from 'store/modules/authSlice';

export const useReferredBy = () => {
  const navigate = useNavigate();
  const { referer, refer_key: keyFromStore } = useSelector(selectAuth);
  const { refer_key } = useParams();
  const ref = refer_key ?? keyFromStore;
  const dispatch = useAppDispatch();

  const getUserByReferredKey = async () => {
    if (!ref) {
      return;
    }

    window.history.replaceState(null, '', `/sign-up/${ref}`);
    const { payload } = await dispatch(tryGetUserByReferredKey({ refer_key: ref }));
    if (!payload) {
      navigate('/sign-up/invalid', { replace: true });
    }
  };

  useEffect(() => {
    getUserByReferredKey();
  }, [ref, dispatch]);

  return {
    refer_key: ref,
    referer
  };
};
