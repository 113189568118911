import { motion } from 'framer-motion';
import { FC } from 'react';
import { Split } from '../Split';
import styles from './Setting.module.scss';

interface ISettingItem {
  title: string;
  desc: string;
  children: React.ReactNode;
}

export const SettingItem: FC<ISettingItem> = ({ title, desc, children }) => {
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className={styles.root}>
        <div className={styles.left}>
          <span className={styles.title}>{title}</span>
          <span className={styles.desc}>{desc}</span>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
      <Split className={styles.split} />
    </motion.div>
  );
};
