import { FC, useEffect, useState } from 'react';
import { DPlate } from './DPlate';
import { selectUserProjectsSelectedId, useSelector } from 'store';

import cn from 'classnames';
import styles from './DPartnersInfo.module.scss';

interface Props {
  size: number;
  strokeWidth: number;
  percentage: number;
  color: string;
  className: string;
}

const CircularProgress: FC<Props> = ({ size, strokeWidth, percentage, color, className }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);

  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  return (
    <svg className={className} width={size} height={size} viewBox={viewBox}>
      <circle
        fill="none"
        stroke="#ccc"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        fill="none"
        stroke={color}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        strokeDasharray={[dash, circumference - dash].join(',')}
        // strokeLinecap="round"
        style={{ transition: 'all 0.5s' }}
      />
      <text
        className={styles.graphPercent}
        fill="currentColor"
        x="50%"
        y="35%"
        dy="20px"
        textAnchor="middle"
      >
        {`${progress}%`}
      </text>
      <text
        className={styles.graphDescription}
        fill="currentColor"
        x="50%"
        y="49%"
        dy="20px"
        textAnchor="middle"
      >
        конверсия
      </text>
    </svg>
  );
};

export const DPartnersInfo = () => {
  const selected = useSelector(selectUserProjectsSelectedId);

  return (
    <DPlate
      title="Партнерка"
      linkName="Вся аналитика"
      link={`/${selected}/analytics`}
      selectOption="весь"
    >
      <div className={cn(styles.row, styles.graph)}>
        <div className={styles.col}>
          <div className={styles.graphPreview}>
            <CircularProgress
              className={styles.graphCircle}
              size={140}
              strokeWidth={28}
              percentage={60}
              color={styles.c_accent}
            />
          </div>
        </div>
        <div className={cn(styles.col, styles.graphCaption)}>
          <div className={styles.graphLabel}>
            <div className={styles.graphLabelColor} style={{ backgroundColor: styles.c_stroke }} />
            <div className={styles.graphLabelTitle}>клики</div>
          </div>
          <div className={styles.graphLabel}>
            <div className={styles.graphLabelColor} style={{ backgroundColor: styles.c_accent }} />
            <div className={styles.graphLabelTitle}>продажи</div>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.statistic}>
            <div className={styles.statisticTitle}>234</div>
            <div className={styles.statisticDesc}>клики</div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.statistic}>
            <div className={styles.statisticTitle}>140</div>
            <div className={styles.statisticDesc}>продажи</div>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={cn(styles.col, styles.isFluid)}>
          <div className={styles.statistic}>
            <div className={styles.statisticTitle}>33 600 ₽</div>
            <div className={styles.statisticDesc}>доходность</div>
          </div>
        </div>
      </div>
    </DPlate>
  );
};
