import { FC } from 'react';
import { motion } from 'framer-motion';
import { Icon } from 'stories';

import cn from 'classnames';
import styles from './InlineDialogFooter.module.scss';

import icon_dots_20 from 'assets/sprite/20/dots_20.svg';
import icon_approve_20 from 'assets/sprite/20/approve_20.svg';
import icon_close_20 from 'assets/sprite/20/close_20.svg';
import icon_google_50 from 'assets/sprite/50/google_50.svg';
import icon_vk_50 from 'assets/sprite/50/vk_50.svg';
import icon_yandex_50 from 'assets/sprite/50/yandex_50.svg';

export enum InlineDialogFooterSocType {
  Google = 'google',
  Yandex = 'yandex',
  vk = 'vk'
}

interface InlineDialogFooterProps {
  isPending: boolean;
  isValid: boolean;
  isTouched: boolean;
  submitTitle: string;
  cancelTitle?: string;
  onSubmitClick: () => void;
  onCancelClick?: () => void;
  onSelectSocial?: (type: InlineDialogFooterSocType) => void;
}

export const InlineDialogFooter: FC<InlineDialogFooterProps> = props => {
  const {
    isPending,
    isValid,
    isTouched,
    submitTitle,
    onSubmitClick,
    cancelTitle,
    onCancelClick,
    onSelectSocial
  } = props;

  return (
    <div className={styles.footer}>
      {cancelTitle && (
        <button
          className={cn(styles.trigger, styles.isSecond)}
          onClick={onCancelClick}
          type="button"
        >
          <Icon
            className={styles.triggerIcon}
            viewBox={icon_close_20.viewBox}
            id={icon_close_20.id}
          ></Icon>
          <span className={styles.triggerDesc}>{cancelTitle}</span>
        </button>
      )}

      <button
        className={cn(styles.trigger, {
          [styles.pr20]: onSelectSocial,
          [styles.isFluid]: cancelTitle,
          [styles.isPending]: isPending
        })}
        onClick={onSubmitClick}
        disabled={(!isValid && isTouched) || isPending}
        type="button"
      >
        <motion.div
          className={styles.tiggerBlade}
          animate={{
            skewX: [30, 30, 30],
            left: ['-50%', '50%', '130%'],
            opacity: [0, 0.75, 1]
          }}
          transition={{
            duration: 4,
            ease: 'linear',
            repeat: Infinity,
            repeatDelay: 1
          }}
        ></motion.div>
        <motion.div
          className={styles.tiggerBlade2}
          animate={{
            skewX: [30, 30, 30],
            left: ['-100%', '50%', '130%'],
            opacity: [0, 0.75, 1]
          }}
          transition={{
            duration: 4,
            ease: 'backOut',
            repeat: Infinity,
            repeatDelay: 1,
            delay: 0.2
          }}
        ></motion.div>
        {isPending ? (
          <>
            <Icon
              className={styles.triggerIcon}
              viewBox={icon_dots_20.viewBox}
              id={icon_dots_20.id}
            ></Icon>
            <span className={styles.triggerDesc}>Загрузка</span>
          </>
        ) : (
          <>
            <Icon
              className={styles.triggerIcon}
              viewBox={icon_approve_20.viewBox}
              id={icon_approve_20.id}
            ></Icon>
            <span className={styles.triggerDesc}>{submitTitle}</span>
          </>
        )}
      </button>

      {onSelectSocial && (
        <>
          <div className={styles.divider}>или</div>
          <div className={styles.social}>
            <div
              className={styles.socialItem}
              onClick={() => onSelectSocial(InlineDialogFooterSocType.Google)}
            >
              <Icon
                className={styles.socialIcon}
                viewBox={icon_google_50.viewBox}
                id={icon_google_50.id}
              ></Icon>
            </div>
            <div
              className={styles.socialItem}
              onClick={() => onSelectSocial(InlineDialogFooterSocType.Yandex)}
            >
              <Icon
                className={styles.socialIcon}
                viewBox={icon_yandex_50.viewBox}
                id={icon_yandex_50.id}
              ></Icon>
            </div>
            <div
              className={styles.socialItem}
              onClick={() => onSelectSocial(InlineDialogFooterSocType.vk)}
            >
              <Icon
                className={styles.socialIcon}
                viewBox={icon_vk_50.viewBox}
                id={icon_vk_50.id}
              ></Icon>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
