import { memo, useCallback, useEffect, useState } from 'react';
import { FormikTouched, FormikErrors } from 'formik';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';

import styles from '../Control.module.scss';

interface ErrorHandleCtrlProps {
  touched: FormikErrors<string>;
  error: FormikTouched<boolean>;
}

export const ErrorHandleCtrl = memo<ErrorHandleCtrlProps>(props => {
  const { touched, error } = props;
  const [msg, setMsg] = useState(error);

  const errorControls = useAnimation();
  const msgControls = useAnimation();

  const expandedSequence = useCallback(async () => {
    errorControls.set({ width: 0 });
    msgControls.set({ opacity: 0 });
    await errorControls.start({ width: 'auto', transition: { duration: 0.2 } });
    msgControls.start({ opacity: 1, transition: { duration: 0.2 } });
  }, [errorControls, msgControls]);

  const collapsedSequence = useCallback(async () => {
    await msgControls.start({ opacity: 0, transition: { duration: 0.2 } });
    await errorControls.start({ width: 0, transition: { duration: 0.2 } });

    setMsg(null);
  }, [errorControls, msgControls]);

  useEffect(() => {
    if (error) {
      setMsg(error);
    }
  }, [error]);

  useEffect(() => {
    errorControls.stop();
    msgControls.stop();

    if (touched && error) {
      expandedSequence();
    }
    if (touched && !error && msg) {
      collapsedSequence();
    }
  }, [touched, error, msg, expandedSequence, collapsedSequence, errorControls, msgControls]);

  return (
    <div className={styles.error}>
      <AnimatePresence>
        <motion.span className={styles.errorMsg} animate={errorControls}>
          <motion.span className={styles.errorMsgInner} animate={msgControls}>
            {msg}
          </motion.span>
        </motion.span>
      </AnimatePresence>
    </div>
  );
});
