import { AnimatePresence, motion } from 'framer-motion';
import { VideoContext } from 'modules/+CoursePage/contexts/VideoContext';
import { useContext } from 'react';
import { selectUserProjectsSelectedId, useAppDispatch, useSelector } from 'store';
import { setActiveVideoIndex, setIsVideoWatched, unlockVideo } from 'store/modules/courseSlice';
import { selectCourse } from 'store/selectors/course';
import { DialogHeader } from 'stories/Dialog/components';
import downArrowIcon from 'assets/sprite/20/downArrow_20.svg';
import styles from './VideoBlock.module.scss';
import { Icon } from 'stories';
import { useNavigate, useParams } from 'react-router-dom';
import { useBreakpoints } from 'hooks';

export const VideoBlock = () => {
  const dispatch = useAppDispatch();
  const projectSelectedId = useSelector(selectUserProjectsSelectedId);
  const { activeCourse, activeVideoIndex } = useSelector(selectCourse);

  const { isTabletMax } = useBreakpoints();

  const params = useParams();
  const navigate = useNavigate();

  const { isActiveVideo, toggle } = useContext(VideoContext);

  const educationId = params.education_id;
  const previousVideoItem = activeCourse.videos[activeVideoIndex - 1];
  const nextVideoItem = activeCourse.videos[activeVideoIndex + 1];
  // const parsedUrl = new URL(url);
  // const videoId = parsedUrl.searchParams.get('v');
  // const prefix = 'https://www.youtube.com/watch?v=';
  // const replacedUrl = url.startsWith(prefix)
  //   ? url.replace(url, `https://www.youtube.com/embed/${videoId}`)
  //   : '';

  const onClickPreviousVideo = () => {
    if (previousVideoItem?.id) {
      dispatch(setActiveVideoIndex(activeVideoIndex - 1));
      navigate(`/${projectSelectedId}/education/${educationId}/${previousVideoItem.id}`);
    }
  };

  const onClickNextVideo = () => {
    dispatch(setIsVideoWatched(activeCourse.videos[activeVideoIndex].id));
    if (nextVideoItem?.id) {
      dispatch(setActiveVideoIndex(activeVideoIndex + 1));
      dispatch(unlockVideo(Number(nextVideoItem.id)));
      navigate(`/${projectSelectedId}/education/${educationId}/${nextVideoItem.id}`);
    }
  };

  return (
    <AnimatePresence>
      {isActiveVideo && (
        <motion.div
          className={styles.videoBlock}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
        >
          <DialogHeader className={styles.header} onClose={toggle} title="Просмотр курса" />
          <iframe
            width="100%"
            height="100%"
            src={activeCourse?.videos[activeVideoIndex]?.embed}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div
            className={styles.bottomButtons}
            style={{ justifyContent: previousVideoItem?.id ? 'space-between' : 'flex-end' }}
          >
            {previousVideoItem?.id && (
              <button type="button" onClick={onClickPreviousVideo}>
                <Icon
                  viewBox={downArrowIcon.viewBox}
                  id={downArrowIcon.id}
                  className={styles.backIcon}
                ></Icon>
                {!isTabletMax ? 'Вернуться назад' : 'Назад'}
              </button>
            )}

            <button type="button" onClick={onClickNextVideo}>
              <span>{!isTabletMax ? 'Я посмотрел видео' : 'Я посмотрел'}</span>
              <Icon
                viewBox={downArrowIcon.viewBox}
                id={downArrowIcon.id}
                className={styles.watchedVideoIcon}
              ></Icon>
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
