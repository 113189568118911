/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field } from 'formik';
import { FC } from 'react';
import { AvatarPreview } from './components';

import cn from 'classnames';
import styles from './AvatarControl.module.scss';

interface IAvatarControlProps {
  name: string;
  className?: string;
}

export const AvatarControl: FC<IAvatarControlProps> = props => {
  const { name, className } = props;

  return (
    <Field name={name}>
      {({ form: { setFieldValue }, meta }) => {
        const handleOnChange = (event: any) => {
          setFieldValue(name, event.currentTarget.files[0]);
        };

        return (
          <label
            className={cn(
              styles.wrapper,
              { [styles.isGreen]: meta.touched && meta.error === undefined },
              className
            )}
          >
            <input className={styles.input} type="file" onChange={handleOnChange} />
            <AvatarPreview file={meta.value} />
          </label>
        );
      }}
    </Field>
  );
};
