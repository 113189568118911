import { Link } from 'react-router-dom';
import { HelpTip, Icon, InlineDialogHeader } from 'stories';
import { motion } from 'framer-motion';

import shared from './Login.module.scss';
import styles from './RegisterInvalid.module.scss';

import icon_danger_24 from 'assets/sprite/24/danger_24.svg';

import img_invalidRegister from 'assets/images/auth/invalid-register.svg';
import cn from 'classnames';

export const RegisterInvalid = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <InlineDialogHeader title="Регистрация" />

      <div className={shared.navigation}>
        <span>Уже есть аккаунт?</span>&nbsp;<Link to="/sign-in">Войти ➝</Link>
      </div>

      <div className={shared.alert}>
        <div className={shared.message}>
          <Icon
            className={cn([shared.messageIcon, styles.messageIcon])}
            viewBox={icon_danger_24.viewBox}
            id={icon_danger_24.id}
          ></Icon>
          <span className={cn([shared.messageTxt, styles.messageTxt])}>
            Запросите ссылку-приглашение
          </span>
        </div>
        <div className={styles.help}>
          <HelpTip />
          <span className={styles.helpTxt}>Что это такое?</span>
        </div>
        <div className={shared.preview}>
          <img src={img_invalidRegister} alt="invalid register preview" />
        </div>
      </div>
    </motion.div>
  );
};
