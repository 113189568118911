import { FC } from 'react';
import styles from './CourseDate.module.scss';

interface Props {
  date: string;
}

export const CourseDate: FC<Props> = ({ date }) => {
  return <div className={styles.courseDate}>{date}</div>;
};
