import useBreakpoint from 'use-breakpoint';

enum Breaks {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
  largeDesktop = 'largeDesktop'
}

const BREAKPOINTS = {
  [Breaks.mobile]: 0,
  [Breaks.tablet]: 768,
  [Breaks.desktop]: 992,
  [Breaks.largeDesktop]: 1360
};

export const useBreakpoints = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const isMobile = breakpoint === Breaks.mobile;
  const isTablet = breakpoint === Breaks.tablet;
  const isDesktop = breakpoint === Breaks.desktop;
  const isLargeDesktop = breakpoint === Breaks.largeDesktop;

  const isTabletMax = isTablet || isMobile;
  const isDesktopMin = isDesktop || isLargeDesktop;

  return {
    isMobile,
    isTablet,
    isDesktop,
    isLargeDesktop,
    isTabletMax,
    isDesktopMin
  };
};
