import { selectUserProjectsSelectedId, useSelector } from 'store';
import { DPlate } from './DPlate';

import styles from './DStatistics.module.scss';

export const DStatistics = () => {
  const selected = useSelector(selectUserProjectsSelectedId);

  return (
    <DPlate
      title="Статистика"
      linkName="Вся статистика"
      link={`/${selected}/analytics`}
      selectOption="24 часа"
    >
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.title}>850</div>
          <div className={styles.description}>баланс RV</div>
        </div>
        <div className={styles.col}>
          <div className={styles.title}>2542</div>
          <div className={styles.description}>бонусов</div>
        </div>
      </div>
    </DPlate>
  );
};
