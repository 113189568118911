import { NavLink, useLocation } from 'react-router-dom';
import { Avatar, Icon } from 'stories';
import { selectUserProjects, setSelectedProject, useSelector } from 'store';
import { AnimateSharedLayout, motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { getLocationLevelPath } from 'utils';
import { useEffect, useState } from 'react';
import { sidebarNavRoutes } from 'modules';

import cn from 'classnames';
import styles from './SidebarProjects.module.scss';

import icon_brand_24 from 'assets/sprite/24/brand_24.svg';
import icon_add_24 from 'assets/sprite/24/add_24.svg';

const spring = {
  type: 'spring',
  stiffness: 500,
  damping: 30
};

export const SidebarProjects = () => {
  const pathname = useLocation().pathname;
  const [secondLevel, setSecondLevel] = useState('');
  const { projects } = useSelector(selectUserProjects);
  const dispatch = useDispatch();

  const validateSecondLevel = () => {
    const path = getLocationLevelPath(pathname, 1, 2);
    const savedPath = sidebarNavRoutes.some(route => route.url === path) ? `/${path}` : '';

    setSecondLevel(savedPath);
  };
  useEffect(validateSecondLevel, [pathname]);

  const setProject = (id: number) => {
    dispatch(setSelectedProject(id));
  };

  return (
    <AnimateSharedLayout>
      <div className={styles.projects}>
        <div className={styles.list}>
          {projects.map(project => {
            const isSelected = `${getLocationLevelPath(pathname)}` === `${project.id}`;

            return (
              <NavLink
                key={project.id}
                className={cn(styles.project, { [styles.isSelected]: isSelected })}
                to={`/${project.id}${secondLevel}`}
                replace
                onClick={() => setProject(project.id)}
              >
                {isSelected && (
                  <motion.div
                    className={styles.outline}
                    layoutId="outline"
                    transition={spring}
                    initial={false}
                  />
                )}
                {project.id === 1 ? (
                  <div className={styles.brand}>
                    <Icon
                      className={styles.icon}
                      viewBox={icon_brand_24.viewBox}
                      id={icon_brand_24.id}
                    ></Icon>
                  </div>
                ) : (
                  <div className={styles.preview}>
                    <Avatar
                      className={styles.avatar}
                      isSquare={true}
                      photo={project.photo}
                      firstName={project.name}
                      lastName={project.name.slice(1, project.name.length - 1)}
                    />
                  </div>
                )}
              </NavLink>
            );
          })}
        </div>
        <NavLink key="create" className={styles.project} to="/create" replace>
          <div className={styles.trigger}>
            <Icon className={styles.plus} viewBox={icon_add_24.viewBox} id={icon_add_24.id}></Icon>
          </div>
        </NavLink>
      </div>
    </AnimateSharedLayout>
  );
};
