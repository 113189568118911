import icon_danger_24 from 'assets/sprite/24/danger_24.svg';
import { motion } from 'framer-motion';
import { Icon } from 'stories';

import styles from './Warning.module.scss';

import analytics_person from 'assets/images/modules/analytics/analytics_person.svg';

export const Warning = () => {
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className={styles.root}>
        <div className={styles.content}>
          <Icon className={styles.icon} viewBox={icon_danger_24.viewBox} id={icon_danger_24.id} />
          <div className={styles.info}>
            <span className={styles.desc}>
              Данный раздел в процессе создания, мы уведомим когда он запустится
            </span>
          </div>
        </div>
        <div className={styles.imageBox}>
          <img className={styles.img} src={analytics_person} />
        </div>
      </div>
    </motion.div>
  );
};
