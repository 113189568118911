import { AnimatePresence, motion } from 'framer-motion';
import { FC, useState } from 'react';

import icon_document from 'assets/sprite/24/document_24.svg';
import icon_down_arrow from 'assets/sprite/20/downArrow_20.svg';
import { Icon } from 'stories';
import styles from './AccordionFilesList.module.scss';
import { AccordionItem } from '../AccordionItem';
import { DocumentDTO } from 'api';

interface Props {
  items: DocumentDTO[];
}

export const AccordionFilesList: FC<Props> = ({ items }) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const itemsFiltered = items?.filter(it => Boolean(it.file)) || [];

  const onToggleActive = () => {
    setIsActive(!isActive);
  };

  if (itemsFiltered.length === 0) {
    return null;
  }

  return (
    <motion.div className={styles.accordion}>
      <button type="button" className={styles.button} onClick={onToggleActive}>
        <div className={styles.text}>
          <Icon
            viewBox={icon_document.viewBox}
            id={icon_document.id}
            className={styles.documentIcon}
          ></Icon>
          Все материалы к курсу ({itemsFiltered?.length})
        </div>
        <motion.div animate={{ rotate: isActive ? 180 : 0 }}>
          <Icon
            viewBox={icon_down_arrow.viewBox}
            id={icon_down_arrow.id}
            className={styles.arrow}
          ></Icon>
        </motion.div>
      </button>
      <AnimatePresence>
        {isActive && (
          <motion.div className={styles.materials}>
            {itemsFiltered.map(item => {
              return <AccordionItem key={item.id} name="file" url={item.file} />;
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
