import { ApiErrorResponse } from 'api';
import axios, { AxiosError } from 'axios';

interface IErrorBase<T> {
  error: Error | AxiosError<T> | ApiErrorResponse;
  type: 'axios-validation-error' | 'axios-error' | 'stock-error';
}

interface IAxiosValidationError extends IErrorBase<ApiErrorResponse> {
  error: ApiErrorResponse;
  type: 'axios-validation-error';
}

interface IAxiosError<T> extends IErrorBase<T> {
  error: AxiosError<T>;
  type: 'axios-error';
}

interface IStockError<T> extends IErrorBase<T> {
  error: Error;
  type: 'stock-error';
}

type IAxiosErrorResponse<T> = IAxiosValidationError | IAxiosError<T> | IStockError<T>;
export type IAxiosErrorPayload<T> = AxiosError<ApiErrorResponse> | AxiosError<T> | Error;

export function axiosErrorHandler<T>(error: unknown): IAxiosErrorResponse<T> {
  if (axios.isAxiosError(error)) {
    if ((error as AxiosError<ApiErrorResponse>).response.data.errors) {
      const data = (error as AxiosError<ApiErrorResponse>).response.data;
      const formikErrors = {};
      Object.keys(data.errors).forEach(key => {
        formikErrors[key] = data.errors[key][0];
      });
      data.errors = formikErrors;

      return {
        error: (error as AxiosError<ApiErrorResponse>).response.data,
        type: 'axios-validation-error'
      };
    } else {
      return {
        error: error as AxiosError<T>,
        type: 'axios-error'
      };
    }
  } else {
    return {
      error: error as Error,
      type: 'stock-error'
    };
  }
}
