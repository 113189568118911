import { motion } from 'framer-motion';
import { ArticleHeader } from 'modules';
import { Warning } from './components/Warning';

export const AnalyticsPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <ArticleHeader title="Аналитика" />
      <Warning />
    </motion.div>
  );
};
