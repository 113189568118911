import { Field } from 'formik';
import { Icon } from 'stories';

import cn from 'classnames';
import styles from './CheckboxesCtrl.module.scss';

import icon_approve_20 from 'assets/sprite/20/approve_20.svg';

export const Checkboxes = props => {
  const { label, name, classNameLabel, ...rest } = props;
  return (
    <div className={styles.root}>
      <Field name={name} {...rest}>
        {({ field, meta }) => {
          return (
            <label
              className={cn(styles.square, {
                [styles.isChecked]: field.value,
                [styles.hasError]: meta.error
              })}
            >
              <input type="checkbox" id={name} className={styles.input} {...field} />
              <Icon
                className={styles.icon}
                viewBox={icon_approve_20.viewBox}
                id={icon_approve_20.id}
              ></Icon>
            </label>
          );
        }}
      </Field>
      <label className={cn(styles.description, classNameLabel)} htmlFor={name}>
        {label}
      </label>
    </div>
  );
};
