import { Formik, FormikProps } from 'formik';
import { motion } from 'framer-motion';
import { ArticleHeader } from 'modules';
import { useEffect, useRef } from 'react';
import { Appearance } from './components/Appearance/Appearance';
import { GeneralSettings } from './components/GeneralSettings/GeneralSettings';
import { NotificationSettings } from './components/NotificationSettings/NotificationSettings';
import { Support } from './components/Support/Support';

type FormValues = { [x: string]: unknown };

export const SettingsPage = () => {
  const formikRef = useRef<FormikProps<FormValues>>();
  const initialValues = {
    color: ''
  };

  const onSubmit = async (values: typeof initialValues) => {
    console.log(values);
  };

  useEffect(() => {
    formikRef.current.setFieldValue('color', 'blue');
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div>
        <ArticleHeader title="Настройки" />
        <GeneralSettings />
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ values }) => {
            return (
              <>
                <Appearance values={values} />
                <Support />
                <NotificationSettings />
              </>
            );
          }}
        </Formik>
      </div>
    </motion.div>
  );
};
