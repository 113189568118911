import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { selectUserProjects, setSelectedProject } from 'store';
import { useSelector } from 'store/utils';

// TODO добавить перенаправление сразу на приглашенный проект когда
export const useSetSelectedProject = () => {
  const { projects, selected } = useSelector(selectUserProjects);
  const { project_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (projects.length === 0) {
      return;
    }

    if (!project_id) {
      const selectedId = selected ? projects.find(p => p.id === selected).id : projects[0].id;
      setLoaded(true);
      navigate(`/${selectedId}`);
      dispatch(setSelectedProject(selectedId));
      return;
    }

    const projectId = parseInt(project_id, 10);
    const isValidId = projects.some(project => project.id === projectId);
    if (isValidId) {
      setLoaded(true);
      dispatch(setSelectedProject(projectId));
      return;
    }

    const selectedId = projects[0].id;
    setLoaded(true);
    navigate(`/${selectedId}`);
    dispatch(setSelectedProject(selectedId));
  }, [dispatch, navigate, project_id, projects.length]);

  return { loaded };
};
