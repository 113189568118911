import styles from './ColorSelector.module.scss';
import formStyles from '../../../Settings.module.scss';
import cn from 'classnames';
import { Field } from 'formik';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ColorSelector = ({ values, ...rest }) => {
  const options = [
    {
      key: '#1D63CB',
      value: 'blue'
    },
    {
      key: '#F3A685',
      value: 'orange'
    },
    {
      key: '#399C8A',
      value: 'green'
    }
  ];
  return (
    <div className={styles.root}>
      <div className={cn(formStyles.group)}>
        <label className={cn(formStyles.label)}>{'Цветовая схема'}</label>
        <div className={cn(styles.selector)}>
          <Field name={'color'}>
            {formik => {
              const { field } = formik;
              return options.map(option => {
                return (
                  <div key={option.key}>
                    <input
                      type="radio"
                      id={option.value}
                      className={cn(styles.radio)}
                      {...field}
                      value={option.value}
                      checked={field.value === option.value}
                    />
                    <label
                      style={{ backgroundColor: option.key }}
                      className={cn(styles.circle, {
                        [styles.active]: field.value === option.value
                      })}
                      htmlFor={option.value}
                    ></label>
                  </div>
                );
              });
            }}
          </Field>
        </div>
      </div>
    </div>
  );
};
