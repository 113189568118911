import { Navigate, Outlet } from 'react-router-dom';
import { selectAuthToken, selectUser, useSelector } from 'store';

export const PrivateOutlet = () => {
  const token = useSelector(selectAuthToken);
  const { status, profile } = useSelector(selectUser);
  const isValid = Boolean(profile && profile.name && profile.lastname && profile.login);

  if (!token) {
    return <Navigate to="/sign-in" />;
  }

  if (status === 'idle' || status === 'pending') {
    return <div>Loading...</div>;
  }

  return isValid ? <Outlet /> : <Navigate to="/profile-setup" replace />;
};
