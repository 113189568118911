import axios from 'axios';
import { CourseDTO } from './course';

export interface EducationPayload {
  userId: number;
}

export interface DocumentDTO {
  id: number;
  file: string;
}

export interface HelpDTO {
  id: number;
  key: string;
  name: string;
  tooltip: string;
  description: string;
}

export interface EducationDTO {
  courses: CourseDTO[];
  statuses: unknown;
  help: HelpDTO[];
}

export interface EducationsParams {
  userId: number;
  searchParams: unknown;
}

export const getEducations = (params: EducationsParams) => {
  return axios.get<{ data: EducationDTO }>(
    `/api/education/for/${params.userId}?${params.searchParams}`
  );
};
