import axios from 'axios';
import { HelpTipDTO, ProfileLinkDTO } from 'api';
import { TeammateStatusDTO } from 'api/config';

export interface TeammateDTO {
  id: number;
  login: string;
  name: string;
  lastname: string;
  full_name: string;
  short_name: string;
  photo: string;
  created_at: string;
  child_count: number;
  child: TeammateDTO[];
  links?: ProfileLinkDTO[]; // TODO add this from the backend
  status: TeammateStatusDTO;
}

export interface TeamPageDTO {
  team: TeammateDTO[];
  statuses: {
    message: string;
  };
  help: HelpTipDTO[];
}

export const getTeamInfo = (projectId: number) => {
  return axios.get<{ data: TeamPageDTO }>(`/api/team/for/${projectId}`);
};

export const setTeammateStatus = (teammateId: number, status_id: number) => {
  return axios.post<{ data: TeammateDTO }>(`/api/partner/find/${teammateId}/set_status`, {
    status_id
  });
};
