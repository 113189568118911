import styles from './DProfile.module.scss';
import { useSelector } from 'store';
import { selectDashboardProfile } from 'store/selectors/dashboard';

import { Avatar, Icon } from 'stories';

import icon_brand_24 from 'assets/sprite/24/brand_24.svg';

export const DProfile = () => {
  const profile = useSelector(selectDashboardProfile);
  const { photo, name, lastname, login, id } = profile ?? {};

  return (
    <div className={styles.plate}>
      <div className={styles.avatar}>
        <Icon className={styles.icon} viewBox={icon_brand_24.viewBox} id={icon_brand_24.id}></Icon>
        <Avatar className={styles.view} photo={photo} firstName={name} lastName={lastname} />
      </div>
      <div className={styles.info}>
        <div className={styles.title}>
          {name} {lastname}
        </div>
        <div className={styles.description}>
          {login && (
            <>
              <span>@{login}</span>
              <span className={styles.divider}>/</span>
            </>
          )}
          <span>id{id}</span>
        </div>
      </div>
    </div>
  );
};
