import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEducations, CourseDTO, EducationDTO, EducationsParams } from 'api';
import { StoreValidationErrorsType } from 'store';

type EducationState = {
  courses: CourseDTO[];
  errors: StoreValidationErrorsType;
};

const initialState: EducationState = {
  courses: [],
  errors: null
};

export const tryGetEducation = createAsyncThunk<EducationDTO, EducationsParams>(
  'user/education',
  async (payload, thunkAPI) => {
    try {
      const { data } = await getEducations(payload);

      return data.data;
    } catch (error) {
      // TODO parse message here and pass it rejectWithValue method.
      return thunkAPI.rejectWithValue('CHANGE MSG HERE');
    }
  }
);

const educationSlice = createSlice({
  name: 'education',
  initialState,
  reducers: {
    resetEducationState() {
      return { ...initialState };
    }
  },
  extraReducers: builder => {
    builder.addCase(tryGetEducation.fulfilled, (state, { payload }) => {
      state.courses = payload.courses;
    });
  }
});

export const { resetEducationState } = educationSlice.actions;
const { reducer } = educationSlice;

export default reducer;
