import { memo, useState, useEffect } from 'react';
import { Icon } from 'stories';

import styles from './AvatarPreview.module.scss';

import icon_add_24 from 'assets/sprite/24/add_24.svg';
import icon_brand_24 from 'assets/sprite/24/brand_24.svg';

interface IAvatarControlThumbProps {
  file: File;
}

export const AvatarPreview = memo<IAvatarControlThumbProps>(props => {
  const { file } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [thumb, setThumb] = useState<string>();

  useEffect(() => {
    if (!file) return;

    setIsLoading(true);
    const reader = new FileReader();
    reader.onloadend = () => {
      setIsLoading(false);
      setThumb(reader.result as string);
    };
    reader.readAsDataURL(file);
  }, [file]);

  return (
    <div className={styles.thumb}>
      <Icon className={styles.union} viewBox={icon_brand_24.viewBox} id={icon_brand_24.id}></Icon>
      <div className={styles.preview}>
        {file ? (
          <img className={styles.image} src={thumb} alt={file.name} />
        ) : (
          <>
            <Icon
              className={styles.placeholderIcon}
              viewBox={icon_add_24.viewBox}
              id={icon_add_24.id}
            ></Icon>
            <span className={styles.placeholderTxt}>Загрузить фото</span>
          </>
        )}
      </div>
    </div>
  );
});
