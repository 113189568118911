import { RootState } from 'store';

export const handleThunkCondition = getState => {
  const { auth } = getState() as RootState;
  const fetchStatus = auth.status;

  if (fetchStatus === 'pending') {
    return false;
  }

  return true;
};
