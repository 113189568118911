import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAvailableTeammateStatuses, TeammateStatusDTO } from 'api/config';

type ConfigState = {
  team: {
    statuses: { id: number; name: string }[];
  };
};

const initialState: ConfigState = {
  team: {
    statuses: []
  }
};

export const tryGetAvailableTeammateStatuses = createAsyncThunk<TeammateStatusDTO[], void>(
  'config/team/statuses',
  async (payload, thunkAPI) => {
    try {
      const { data } = await getAvailableTeammateStatuses();

      return data.data;
    } catch (error) {
      // TODO parse message here and pass it rejectWithValue method.
      return thunkAPI.rejectWithValue('CHANGE MSG HERE');
    }
  }
);

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    resetConfigState() {
      return { ...initialState };
    }
  },
  extraReducers: builder => {
    builder.addCase(tryGetAvailableTeammateStatuses.fulfilled, (state, { payload }) => {
      state.team.statuses = payload;
    });
  }
});

export const { resetConfigState } = configSlice.actions;

const { reducer } = configSlice;

export default reducer;
