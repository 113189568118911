import { Form, Formik, FormikProps } from 'formik';
import { FilterControl, IFilterControlProps } from 'stories';
import { FC, useRef } from 'react';

import styles from './ArticleFilters.module.scss';
import cn from 'classnames';
import { FilterSearchParams } from 'stories/FilterSearchParams';

type FormValues = { [x: string]: unknown };

export interface IArticleFiltersProps {
  initialValues: { [key: string]: string | number | boolean };
  filters: Array<IFilterControlProps>;
  disabled?: boolean;
  className?: string;
}
export const ArticleFilters: FC<IArticleFiltersProps> = props => {
  const { initialValues, filters, disabled, className } = props;
  const formikRef = useRef<FormikProps<FormValues>>();

  // const onChange = values => {
  //   console.log('values: ', values);
  //    setSearchParams(values);
  // };

  return (
    <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={() => {}}>
      <Form className={cn(styles.filters, className)}>
        <FilterSearchParams />
        {filters.map(filter => (
          <FilterControl
            key={filter.name}
            name={filter.name}
            options={filter.options}
            icon={filter.icon}
            label={filter.label}
            disabled={disabled}
            {...filter.rest}
          />
        ))}
      </Form>
    </Formik>
  );
};
