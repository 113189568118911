import { motion } from 'framer-motion';
import { useBreakpoints } from 'hooks';
import { useEffect } from 'react';
import {
  resetDashboardState,
  selectUserProjects,
  tryGetDashboardInfo,
  useAppDispatch,
  useSelector
} from 'store';
import { selectDashboardPage } from 'store/selectors/dashboard';
import {
  DProfile,
  DReferralLinks,
  DStatistics,
  DPartnersInfo,
  DTeam,
  DSupport,
  DInviterInfo
} from './components';

import styles from './DashboardPage.module.scss';

export const DashboardPage = () => {
  const { isDesktopMin } = useBreakpoints();
  const { status } = useSelector(selectDashboardPage);
  const { selected } = useSelector(selectUserProjects);
  const dispatch = useAppDispatch();

  const fetchDashboardData = async () => {
    if (selected) {
      try {
        await dispatch(tryGetDashboardInfo({ id: selected }));
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchDashboardData();

    return () => {
      dispatch(resetDashboardState());
    };
  }, []);

  const desktopTmp = (
    <motion.div
      className={styles.row}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4 }}
    >
      <div className={styles.col}>
        <div className={styles.group}>
          <DProfile />
        </div>
        <div className={styles.group}>
          <DPartnersInfo />
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.group}>
          <DStatistics />
        </div>
        <div className={styles.group}>
          <DTeam />
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.group}>
          <DReferralLinks />
        </div>
        <div className={styles.group}>
          <DSupport />
        </div>
        <div className={styles.group}>
          <DInviterInfo />
        </div>
      </div>
    </motion.div>
  );

  const mobileTmp = (
    <motion.div
      className={styles.row}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4 }}
    >
      <div className={styles.group}>
        <DProfile />
      </div>
      <div className={styles.group}>
        <DStatistics />
      </div>
      <div className={styles.group}>
        <DReferralLinks />
      </div>
      <div className={styles.group}>
        <DPartnersInfo />
      </div>

      <div className={styles.group}>
        <DTeam />
      </div>

      <div className={styles.group}>
        <DSupport />
      </div>
      <div className={styles.group}>
        <DInviterInfo />
      </div>
    </motion.div>
  );

  const renderTemplate = () => {
    const isLoading = status === 'idle' || status === 'pending';
    const isDestopTmp = isDesktopMin;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (isDestopTmp) {
      return desktopTmp;
    }

    return mobileTmp;
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      {renderTemplate()}
    </motion.div>
  );
};
