import { useEffect, useState } from 'react';
import { selectUserProjectsSelectedId, useSelector } from 'store';
import { Form, Formik } from 'formik';
import { Control, Icon } from 'stories';
import { DPlate } from './DPlate';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { motion } from 'framer-motion';
import { selectDashboardReferrerKey } from 'store/selectors/dashboard';

import styles from './DReferralLinks.module.scss';
import CT_styles from 'stories/ControlTrigger/ControlTrigger.module.scss';

import icon_document_24 from 'assets/sprite/24/document_24.svg';
import cn from 'classnames';

export const DReferralLinks = () => {
  const selected = useSelector(selectUserProjectsSelectedId);
  const [initialValues, setInitialValues] = useState({
    referralToMainPage: '',
    referralToRegPage: ''
  });
  const refer_key = useSelector(selectDashboardReferrerKey);

  const onSubmit = () => {};

  useEffect(() => {
    const origin = window.location.origin;

    setInitialValues({
      referralToMainPage: `https://platforma.com?ref=${refer_key}`,
      referralToRegPage: `${origin}/sign-up/${refer_key}`
    });
  }, [refer_key]);

  return (
    <DPlate title="Реферальные ссылки" linkName="Генератор ссылок" link={`/${selected}/referral`}>
      <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={onSubmit}>
        {({ values: { referralToMainPage, referralToRegPage } }) => {
          return (
            <Form>
              <div className={cn(styles.group, CT_styles.group)}>
                <Control
                  classNameGroup={CT_styles.controlGroup}
                  classNameField={CT_styles.controlField}
                  control="input"
                  type="text"
                  name="referralToMainPage"
                  label="Главная рефссылка"
                  placeholder={referralToMainPage}
                  disabled
                />
                <CopyToClipboard text={referralToMainPage}>
                  <motion.button
                    className={CT_styles.action}
                    whileTap={{ backgroundColor: CT_styles.c_green }}
                    type="button"
                  >
                    <Icon
                      className={CT_styles.actionIcon}
                      viewBox={icon_document_24.viewBox}
                      id={icon_document_24.id}
                    ></Icon>
                    <span className={CT_styles.actionDesc}>Скоп.</span>
                  </motion.button>
                </CopyToClipboard>
              </div>
              <div className={cn(styles.group, CT_styles.group)}>
                <Control
                  classNameGroup={CT_styles.controlGroup}
                  classNameField={CT_styles.controlField}
                  control="input"
                  type="text"
                  name="referralToRegPage"
                  label="Регистрационная"
                  placeholder={referralToRegPage}
                  disabled
                />
                <CopyToClipboard text={referralToRegPage}>
                  <motion.button
                    className={CT_styles.action}
                    whileTap={{ backgroundColor: CT_styles.c_green }}
                    type="button"
                  >
                    <Icon
                      className={CT_styles.actionIcon}
                      viewBox={icon_document_24.viewBox}
                      id={icon_document_24.id}
                    ></Icon>
                    <span className={CT_styles.actionDesc}>Скоп.</span>
                  </motion.button>
                </CopyToClipboard>
              </div>
            </Form>
          );
        }}
      </Formik>
    </DPlate>
  );
};
