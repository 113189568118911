/* eslint-disable react/jsx-no-undef */
import { Form, Formik, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Control,
  InlineDialogFooter,
  InlineDialogHeader,
  InlineDialogFooterSocType,
  InlineDialogBody
} from 'stories';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import { YUP_MAX_CHARACTERS, YUP_MIN_CHARACTERS, YUP_REQUIRED } from 'const';
import { tryLogin } from 'store/modules/authSlice';

import styles from './Login.module.scss';
import { useEffect, useRef } from 'react';
import { selectAuth, useSelector } from 'store';

type FormValues = { [x: string]: unknown };

export const Login = () => {
  const formikRef = useRef<FormikProps<FormValues>>();
  const { errors, status } = useSelector(selectAuth);
  const dispatch = useDispatch();

  // const initialValues = {
  //   email: 'platform@app.com',
  //   password: 'platform',
  //   rememberMe: true
  // };
  const initialValues = {
    email: 'user@app.com',
    password: 'user',
    rememberMe: true
  };

  // prettier-ignore
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(YUP_REQUIRED('валидный е-мейл'))
      .required(YUP_REQUIRED('е-мейл')),
    // TODO change min to 6 symbols
    password: Yup.string()
      .min(4, YUP_MIN_CHARACTERS(4))
      .max(40, YUP_MAX_CHARACTERS(40))
      .required(YUP_REQUIRED('пароль'))
  });

  const onSubmit = async values => {
    const payload = {
      ...values
    };
    // TODO remove this after server
    delete payload.rememberMe;
    await dispatch(tryLogin(payload));
  };

  // TODO implement social login scenario
  const handleOnSelectSocial = async (type: InlineDialogFooterSocType) => {
    console.log('type: ', type);
  };

  useEffect(() => {
    if (formikRef.current && errors) {
      formikRef.current.setErrors(errors);
    }
  }, [errors]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <InlineDialogHeader title="Авторизация" />

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ submitForm, isValid, touched }) => (
          <Form>
            <InlineDialogBody>
              <Control
                control="input"
                type="email"
                name="email"
                label="Email"
                placeholder="Ваш email..."
              />
              <Control
                control="input"
                type="password"
                name="password"
                label="Пароль"
                placeholder="Пароль..."
              />
              <div className={styles.group}>
                <Control
                  control="checkbox"
                  type="checkbox"
                  label="Запомнить меня"
                  name="rememberMe"
                />
                <Link className={styles.resetLink} to="/reset-password">
                  Забыли пароль?
                </Link>
              </div>
            </InlineDialogBody>
            <InlineDialogFooter
              isPending={status === 'pending'}
              isValid={isValid}
              isTouched={Object.keys(touched).length > 0}
              submitTitle="Войти"
              onSubmitClick={submitForm}
              onSelectSocial={handleOnSelectSocial}
            />
          </Form>
        )}
      </Formik>
    </motion.div>
  );
};
