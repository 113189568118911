import category_24 from 'assets/sprite/24/category_24.svg';
import messages_24 from 'assets/sprite/24/messages_24.svg';
import userProfile_24 from 'assets/sprite/24/userProfile_24.svg';
import folderOpen_24 from 'assets/sprite/24/folderOpen_24.svg';
import chart_24 from 'assets/sprite/24/chart_24.svg';
import share_24 from 'assets/sprite/24/share_24.svg';
import filter_24 from 'assets/sprite/24/filter_24.svg';

export const sidebarNavRoutes = [
  {
    name: 'Дэшборд',
    url: 'dashboard'
  },
  {
    name: 'Списки',
    url: 'lists'
  },
  {
    name: 'Команда',
    url: 'team'
  },
  {
    name: 'Обучение',
    url: 'education'
  },
  {
    name: 'Аналитика',
    url: 'analytics'
  },
  {
    name: 'Рефералка',
    url: 'referral'
  },
  {
    name: 'Настройки',
    url: 'settings'
  }
];

export const sidebarNavConfig = sidebarNavRoutes.map(route => {
  let icon;
  switch (route.url) {
    case 'dashboard':
      icon = category_24;
      break;
    case 'lists':
      icon = messages_24;
      break;
    case 'team':
      icon = userProfile_24;
      break;
    case 'education':
      icon = folderOpen_24;
      break;
    case 'analytics':
      icon = chart_24;
      break;
    case 'referral':
      icon = share_24;
      break;
    case 'settings':
      icon = filter_24;
      break;
    default:
      icon = null;
  }
  return {
    ...route,
    icon
  };
});
