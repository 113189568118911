import axios from 'axios';
import { ProfileDTO, ProfileLinkDTO, ProjectDTO } from 'api';

export interface HelpTipDTO {
  id: number;
  key: string;
  name: string;
  tooltip: string;
  description: string;
}

export interface DashboardProfileDTO {
  id: number;
  login: string;
  name: string;
  lastname: string;
  full_name: string;
  short_name: string;
  photo: string;
}

export interface DashboardReferrerDTO {
  id: number;
  login: string;
  name: string;
  lastname: string;
  full_name: string;
  short_name: string;
  email: string;
  photo: string;
  created_at: string;
  links: ProfileLinkDTO[];
}

export interface DashboardDTO {
  user: DashboardProfileDTO;
  partner: unknown;
  statistic: unknown;
  my_team: unknown;
  refer_key: string;
  support: ProfileLinkDTO[];
  invited: DashboardReferrerDTO;
  help: HelpTipDTO[];
}

export const getProjectDashboardInfo = (projectId: number) => {
  return axios.get<{ data: DashboardDTO }>(`/api/dashboard/for/${projectId}`);
};

export interface ICreateProjectPayload {
  name: string;
  photo?: File;
  short_name?: string;
  cover?: string;
}

export interface ICreateProjectResponse extends ProjectDTO, ProfileDTO {
  user: Partial<ProfileDTO>;
}

export const createProject = (payload: ICreateProjectPayload) => {
  const formData = new FormData();

  formData.append('name', payload.name);

  if (payload.photo) {
    formData.append('photo', payload.photo);
  }

  if (payload.short_name) {
    formData.append('short_name', payload.short_name);
  }

  if (payload.cover) {
    formData.append('cover', payload.cover);
  }

  return axios.post<{ data: ICreateProjectResponse }>('/api/project/new', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
