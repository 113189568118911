// * Base
import {
  Avatar,
  Control,
  InlineDialogBody,
  InlineDialogFooter,
  InlineDialogFooterSocType,
  InlineDialogHeader
} from 'stories';
import { YUP_MAX_CHARACTERS, YUP_MIN_CHARACTERS, YUP_REQUIRED } from 'const';
import { tryRegister } from 'store/modules/authSlice';
import { Form, Formik, FormikProps } from 'formik';
import { selectAuth, useAppDispatch } from 'store';
import { useReferredBy } from './useReferredBy';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import XRegExp from 'xregexp';
import * as Yup from 'yup';

// * Styles
import styles from './Login.module.scss';

type FormValues = { [x: string]: unknown };

export const Register = () => {
  // * Submit text variables & methods
  const checkSubmitText = () => (window?.innerWidth < 420 ? 'Создать' : 'Создать аккаунт');
  // Submit text variable
  const [submitText, setSubmitText] = useState(checkSubmitText());
  // Edit submit text
  const editSubmiteText = () => setSubmitText(checkSubmitText());
  // Resize subscribe
  useEffect(() => {
    window?.addEventListener('resize', editSubmiteText);
    return () => {
      window?.removeEventListener('resize', editSubmiteText);
    };
  }, [submitText]);
  // * End of submit text variables & methods

  const formikRef = useRef<FormikProps<FormValues>>();
  const { errors, status } = useSelector(selectAuth);
  const dispatch = useAppDispatch();
  const { refer_key, referer } = useReferredBy();

  const initialValues = {
    name: '',
    email: '',
    agreement: true
  };

  // prettier-ignore
  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, YUP_MIN_CHARACTERS(3))
      .max(30, YUP_MAX_CHARACTERS(30))
      .matches(XRegExp('^\\p{L}*$'), 'Разрешены только буквы')
      .required('Пожалуйста, введите Ваше имя'),
    email: Yup.string()
      .email(YUP_REQUIRED('валидный е-мейл'))
      .required(YUP_REQUIRED('е-мейл')),
    agreement: Yup.bool()
      .oneOf([true], 'Принять Условия и положения требуется')
  });

  const onSubmit = async values => {
    // TODO remove when will be confirmed
    const password = '123123123123';

    const payload = {
      ...values,
      password,
      password_confirmation: password,
      refer_key
    };
    delete payload.agreement;

    await dispatch(tryRegister(payload));
  };

  // TODO implement social login scenario
  const handleOnSelectSocial = async (type: InlineDialogFooterSocType) => {
    console.log('type: ', type);
  };

  useEffect(() => {
    if (formikRef.current && errors) {
      formikRef.current.setErrors(errors);
    }
  }, [errors]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <InlineDialogHeader title="Регистрация" />

      {referer && (
        <div className={styles.referer}>
          <Avatar
            size={80}
            className={styles.refererAvatar}
            firstName={referer.name}
            lastName={referer.lastname}
            photo={referer.photo}
          />
          <div className={styles.refererDescription}>
            <b>
              {referer.name} {referer.lastname}
            </b>
            &nbsp;<span>приглашает вас</span>
          </div>
        </div>
      )}

      {referer && (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ submitForm, isValid, touched }) => (
            <Form>
              <InlineDialogBody>
                <Control
                  control="input"
                  type="text"
                  name="name"
                  label="Ваше имя"
                  placeholder="Введите ваше имя..."
                />
                <Control
                  control="input"
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Ваш email..."
                />
                <div className={styles.helpText}>На ваш email придет письмо с инструкциями</div>
                <div className={styles.group}>
                  <Control
                    control="checkbox"
                    type="checkbox"
                    name="agreement"
                    label={
                      <span className={styles.groupText}>
                        Согласен с <a href="#">условиями платформы</a>
                      </span>
                    }
                  />
                </div>
              </InlineDialogBody>

              <InlineDialogFooter
                isPending={status === 'pending'}
                isValid={isValid}
                isTouched={Object.keys(touched).length > 0}
                submitTitle={submitText}
                onSubmitClick={submitForm}
                onSelectSocial={handleOnSelectSocial}
              />
            </Form>
          )}
        </Formik>
      )}
    </motion.div>
  );
};
