import { FC, useCallback } from 'react';
import { DialogContainer } from 'stories';

import icon_userAdd_24 from 'assets/sprite/24/userAdd_24.svg';
import { Notification } from 'modules/Dashboard/components/Header/components/Notification';

export const NotificationDialog: FC = () => {
  const data = [
    {
      icon: icon_userAdd_24,
      user: 'Никита Калинин',
      title: 'Пополнение команды',
      desc: 'присоединился к команде Platforma MLM',
      time: '05:45'
    },
    {
      icon: icon_userAdd_24,
      user: '',
      title: 'новый курс от platforma',
      desc: 'Как правильно пользоваться реферальной системой?',
      time: '16:45',
      buttons: [{ title: 'Смотреть курс' }]
    }
  ];

  const onClick = useCallback(() => {
    console.log('Click to notification');
  }, []);

  return (
    <DialogContainer paddingThin={true}>
      {data.map((item, index) => {
        return (
          <Notification
            key={index}
            icon={item.icon}
            user={item.user}
            title={item.title}
            desc={item.desc}
            time={item.time}
            buttons={item.buttons}
            onClick={onClick}
          />
        );
      })}
    </DialogContainer>
  );
};
