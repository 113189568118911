import { FC, useState } from 'react';
import { ArticleFilters } from './ArticleFilters';
import { HelpTip, Icon, IFilterControlProps } from 'stories';
import { ArticleAction, IArticleActionProps } from './ArticleAction';
import { useBreakpoints } from 'hooks';
import cn from 'classnames';

import styles from './ArticleHeader.module.scss';

import icon_filter_20 from 'assets/sprite/20/filter_20.svg';
import icon_downArrow_20 from 'assets/sprite/20/downArrow_20.svg';

interface IArticleHeaderProps {
  title: string;
  initialValues?: { [key: string]: string | number | boolean };
  filters?: Array<IFilterControlProps>;
  disabled?: boolean;
  forAction?: IArticleActionProps;
}

export const ArticleHeader: FC<IArticleHeaderProps> = props => {
  const { initialValues, filters, disabled, forAction } = props;
  const [toggleFilter, setToggleFilter] = useState(false);
  const { isDesktopMin } = useBreakpoints();

  return (
    <>
      <div className={styles.root}>
        <h1 className={styles.title}>
          <span className={styles.titleText}>{props.title}</span>
          <HelpTip />
        </h1>
        {!isDesktopMin && filters && (
          <button
            className={styles.filterBtn}
            onClick={() => {
              setToggleFilter(!toggleFilter);
            }}
          >
            <Icon
              className={styles.filterIco}
              viewBox={icon_filter_20.viewBox}
              id={icon_filter_20.id}
            />
            <div className={styles.filterTxt}>Фильтры</div>
            <Icon
              className={cn(styles.filterArrow, { [styles.isExpanded]: toggleFilter })}
              viewBox={icon_downArrow_20.viewBox}
              id={icon_downArrow_20.id}
            />
          </button>
        )}
      </div>
      <div className={cn({ [styles.isAction]: forAction })}>
        {filters && isDesktopMin && (
          <ArticleFilters
            disabled={disabled}
            className={styles.filters}
            initialValues={initialValues}
            filters={filters}
          />
        )}
        {filters && !isDesktopMin && toggleFilter && (
          <ArticleFilters
            disabled={disabled}
            className={styles.filters}
            initialValues={initialValues}
            filters={filters}
          />
        )}
        {forAction && isDesktopMin && (
          <ArticleAction
            title={forAction.title}
            icon={forAction.icon}
            onClick={forAction.onClick}
          />
        )}
      </div>
    </>
  );
};
