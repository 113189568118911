import { TeammateDTO } from 'api';
import { motion } from 'framer-motion';
import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Teammate } from '../Teammate';

import styles from './TeamTree.module.scss';

interface ITeamTreeProps {
  team: TeammateDTO[];
}

export const TeamTree: FC<ITeamTreeProps> = ({ team }) => {
  return (
    <motion.div
      className={styles.teammate}
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {team.map(props => (
        <Teammate
          key={uuidv4()}
          id={props.id}
          isFirstLevel={true}
          lastname={props.lastname}
          links={[]}
          name={props.name}
          child_count={props.child_count}
          photo={props.photo}
          status={props.status}
          child={props.child}
        />
      ))}
    </motion.div>
  );
};
