import { FC } from 'react';
import { DPlate } from './DPlate';
import { Icon } from 'stories';
import { DashboardReferrerDTO } from 'api';

import styles from './DHasReferer.module.scss';

import icon_email from 'assets/sprite/oval-socials/os_email.svg';
import icon_facebook from 'assets/sprite/oval-socials/os_facebook.svg';
import icon_telegram from 'assets/sprite/oval-socials/os_telegram.svg';
import icon_viber from 'assets/sprite/oval-socials/os_viber.svg';
import icon_whatsapp from 'assets/sprite/oval-socials/os_whatsapp.svg';

const getIcon = (icon: string) => {
  switch (icon) {
    case 'facebook':
      return icon_facebook;
    case 'telegram':
      return icon_telegram;
    case 'viber':
      return icon_viber;
    case 'whatsapp':
      return icon_whatsapp;
    case 'web':
    default:
      return icon_email;
  }
};

interface Props {
  referer: DashboardReferrerDTO;
}

export const DHasReferer: FC<Props> = ({ referer }) => {
  return (
    <DPlate title="Вас пригласил">
      <div className={styles.inviter}>
        <div className={styles.preview}>
          <img className={styles.avatar} src={referer.photo} alt="" />
        </div>
        <div className={styles.caption}>
          <div className={styles.title}>
            {referer.name} {referer.lastname}
          </div>
          <div className={styles.description}>
            {referer.login && (
              <>
                <span>@{referer.login}</span>
                <span className={styles.divider}>/</span>
              </>
            )}
            <span>id{referer.id}</span>
          </div>
          {/* TODO need more information, not right implementation */}
          {referer.links?.slice(0, 2).map(({ link, social }, index) => {
            const iconInstance = getIcon(social.icon);

            return (
              <div className={styles.contact} key={index}>
                <span className={styles.contactLink} title={link}>
                  {link}
                </span>
                <div className={styles.contactSocials}>
                  <div className={styles.contactSocialsItem}>
                    <Icon
                      className={styles.contactSocialsIcon}
                      viewBox={iconInstance.viewBox}
                      id={iconInstance.id}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </DPlate>
  );
};
