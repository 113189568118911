import { useRef } from 'react';

import { ArticleHeader } from 'modules';
import { motion } from 'framer-motion';
import { ListsCard } from './components/ListsCard';
import { Dialog, IDialogType } from 'stories';
import { ListsCardDialog } from './components/ListsCardDialog';
import { useBreakpoints } from 'hooks';
import { ArticleAction } from 'modules/Dashboard/components/ArticleHeader/ArticleAction';

import icon_info_20 from 'assets/sprite/20/info_20.svg';
import icon_calendar_20 from 'assets/sprite/20/calendar_20.svg';
import icon_source_20 from 'assets/sprite/20/source_20.svg';
import icon_add_20 from 'assets/sprite/24/add_24.svg';
import icon_approve_20 from 'assets/sprite/20/approve_20.svg';
import icon_downloadF_20 from 'assets/sprite/20/downloadF_20.svg';

import styles from './ListsPage.module.scss';

export const ListsPage = () => {
  const dialogRef = useRef<IDialogType>();
  const { isDesktopMin } = useBreakpoints();

  const filters = [
    {
      icon: icon_info_20,
      label: 'Статус',
      name: 'status',
      options: [
        { key: 'все', value: 'all' },
        { key: 'не все', value: 'notAll' }
      ]
    },
    {
      icon: icon_calendar_20,
      label: 'дата добавления',
      name: 'date',
      options: [
        { key: 'все', value: 'all' },
        { key: 'не все', value: 'notAll' }
      ]
    },
    {
      icon: icon_source_20,
      label: 'Источник',
      name: 'source',
      options: [
        { key: 'все', value: 'all' },
        { key: 'не все', value: 'notAll' }
      ]
    }
  ];
  const initialValues = {
    status: filters[0].options[0].value,
    date: filters[1].options[0].value,
    source: filters[2].options[0].value
  };

  const data = [
    {
      id: 0,
      status: 'new',
      username: 'Михаил Прохоров',
      date: '10 Окт 2021',
      kindOfTask: 'Телеграм сообщество'
    },
    {
      id: 1,
      status: 'processing',
      username: 'Иван Дорофеев',
      date: '19 Окт 2021',
      kindOfTask: 'Личная встреча'
    },
    {
      id: 2,
      status: 'inTeam',
      username: 'Михаил Прохоров',
      date: '10 Окт 2021',
      kindOfTask: 'Телеграм сообщество'
    },
    {
      id: 3,
      status: 'refusal',
      username: 'Михаил Прохоров',
      date: '10 Окт 2021',
      kindOfTask: 'Телеграм сообщество'
    }
  ];

  const openDialog = () => {
    dialogRef.current.open();
  };

  const forAction = {
    title: 'Добавить заявку',
    icon: icon_add_20,
    onClick: () => openDialog()
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <ArticleHeader
        title="Списки"
        filters={filters}
        initialValues={initialValues}
        disabled={true}
        forAction={forAction}
      />
      <Dialog ref={dialogRef} title={'Добавление заявки'}>
        <ListsCardDialog
          newForm
          buttons={[
            {
              title: 'В архив',
              icon: icon_downloadF_20,
              type: 'submit',
              mode: 'primary'
            },
            {
              title: 'Сохранить',
              icon: icon_approve_20,
              type: 'submit'
            }
          ]}
        />
      </Dialog>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className={styles.lists}
      >
        {data.map(item => (
          <ListsCard
            id={item.id}
            username={item.username}
            status={item.status}
            kindOfTask={item.kindOfTask}
            date={item.date}
            key={item.id}
          />
        ))}
      </motion.div>
      {!isDesktopMin && (
        <ArticleAction title={forAction.title} icon={forAction.icon} onClick={forAction.onClick} />
      )}
    </motion.div>
  );
};
