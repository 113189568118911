import { motion } from 'framer-motion';
import { FC, useContext, useEffect } from 'react';
import icon_approve from 'assets/sprite/20/approve_20.svg';
import icon_lock from 'assets/sprite/24/lock.svg';
import styles from './VideoCourseItem.module.scss';
import { Icon } from 'stories';
import { RatingStars } from 'stories/RatingStars';
import classnames from 'classnames';
import { useBreakpoints } from 'hooks';
import { Link, useParams } from 'react-router-dom';
import { selectUserProjectsSelectedId, useAppDispatch, useSelector } from 'store';
import { VideoContext } from 'modules/+CoursePage/contexts/VideoContext';
import { selectCourse } from 'store/selectors/course';
import { setActiveVideoIndex, unlockVideo } from 'store/modules/courseSlice';

interface Props {
  videoId: number;
  index: number;
  title: string;
  duration: string;
  isDone: boolean;
  isClosed: boolean;
  rating: number;
  posterUrl: string;
}

export const VideoCourseItem: FC<Props> = ({
  videoId,
  index,
  isDone,
  isClosed,
  duration,
  posterUrl,
  title
}) => {
  const dispatch = useAppDispatch();
  const projectSelectedId = useSelector(selectUserProjectsSelectedId);
  const { activeCourse } = useSelector(selectCourse);
  const { toggle } = useContext(VideoContext);
  const params = useParams();
  const { isTabletMax } = useBreakpoints();
  const educationId = params.education_id;
  const isActive = Number(params.video_id) == videoId;

  useEffect(() => {
    dispatch(unlockVideo(Number(params.video_id)));
  }, []);
  const closedPoster = () => {
    return (
      <div className={styles.closedPoster} style={{ backgroundImage: `url(${posterUrl})` }}>
        <div className={styles.greyBg} />
        <Icon viewBox={icon_lock.viewBox} id={icon_lock.id} className={styles.lock}></Icon>
        <span className={styles.closedText}>Доступ откроется после просмотра</span>
      </div>
    );
  };

  const onVideoClick = () => {
    if (isTabletMax) {
      toggle();
    }
    const selectedVideoIndex = activeCourse.videos.findIndex(video => video.id == videoId);
    dispatch(setActiveVideoIndex(selectedVideoIndex));
    dispatch(unlockVideo(videoId));
  };

  return (
    <Link
      // to={!isClosed ? `/${projectSelectedId}/education/${educationId}/${videoId}` : '#'}
      to={`/${projectSelectedId}/education/${educationId}/${videoId}`}
      className={classnames(styles.link, isClosed && styles.closed)}
    >
      <motion.button
        type="button"
        transition={{ duration: 0.3 }}
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: -50 }}
        exit={{ opacity: 0, y: -50 }}
        className={classnames(styles.item, isClosed && styles.closed)}
        style={{ background: isActive ? '#1D63CB' : 'transparent' }}
        onClick={onVideoClick}
      >
        <motion.div className={styles.number} animate={{ color: isActive ? '#fff' : '#292D32' }}>
          {index + 1}
        </motion.div>
        {!isClosed ? (
          <div
            style={{
              backgroundImage: `url(${posterUrl})`,
              justifyContent: !isDone ? 'flex-end' : 'space-between'
            }}
            className={styles.poster}
          >
            {isDone && (
              <div className={styles.approve}>
                <Icon
                  viewBox={icon_approve.viewBox}
                  id={icon_approve.id}
                  className={styles.arrow}
                ></Icon>
              </div>
            )}
            <div className={styles.posterBottom}>
              <RatingStars isSmall rating={4.6} isStarsVisible={false} />
              <div className={styles.duration}>{duration}</div>
            </div>
          </div>
        ) : (
          closedPoster()
        )}
        <motion.h2 className={styles.title} animate={{ color: isActive ? '#fff' : '#292D32' }}>
          {title}
        </motion.h2>
      </motion.button>
    </Link>
  );
};
