import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DashboardDTO, getProjectDashboardInfo } from 'api';
import { handleThunkCondition } from 'store';

interface DashboardState extends DashboardDTO {
  status: 'idle' | 'pending' | 'fulfilled' | 'rejected';
}

const initialState: DashboardState = {
  user: null,
  partner: null,
  statistic: null,
  my_team: null,
  refer_key: null,
  support: null,
  invited: null,
  help: null,
  status: 'idle'
};

interface TryGetDashboardInfoPayload {
  id: number;
}

export const tryGetDashboardInfo = createAsyncThunk<DashboardDTO, TryGetDashboardInfoPayload>(
  'project/dashboard',
  async ({ id }, thunkAPI) => {
    try {
      const { data } = await getProjectDashboardInfo(id);

      if (Array.isArray(data.data.invited)) {
        data.data.invited = null;
      }

      return data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('CHANGE MSG HERE');
    }
  },
  { condition: (payload, { getState }) => handleThunkCondition(getState) }
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetDashboardState() {
      return { ...initialState };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(tryGetDashboardInfo.pending, state => {
        state.status = 'pending';
      })
      .addCase(tryGetDashboardInfo.rejected, state => {
        state.status = 'rejected';
      })
      .addCase(tryGetDashboardInfo.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload,
          status: 'fulfilled'
        };
      });
  }
});

const { reducer } = dashboardSlice;

export const { resetDashboardState } = dashboardSlice.actions;
export default reducer;
