import { DPlate } from './DPlate';

import img_no_referer from 'assets/images/modules/overview/no-referer.svg';

import styles from './DNoReferer.module.scss';

export const DNoReferer = () => {
  return (
    <DPlate title="Вас пригласил">
      <div className={styles.root}>
        <div className={styles.image}>
          <img className={styles.picture} src={img_no_referer} />
        </div>
        <div className={styles.caption}>Вау, вы наивысший уровень</div>
      </div>
    </DPlate>
  );
};
