import icon_file from 'assets/sprite/20/file_20.svg';
import { motion } from 'framer-motion';
import { FC } from 'react';
import { Icon } from 'stories';
import styles from './AccordionItem.module.scss';

interface Props {
  name: string;
  url: string;
}

export const AccordionItem: FC<Props> = ({ name, url }) => {
  return (
    <motion.a
      className={styles.item}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      target="_blank"
      rel="norefferer"
      href={url}
    >
      <Icon viewBox={icon_file.viewBox} id={icon_file.id} className={styles.file}></Icon>
      <span>{name}</span>
    </motion.a>
  );
};
