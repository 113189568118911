import { Control } from 'stories';
import { SettingItem } from '../Settingitem/SettingItem';
import { ColorSelector } from './components/ColorSelector';

export const Appearance = ({ values }) => {
  return (
    <SettingItem title="Внешний вид" desc="Обложка и цветовая схема">
      <Control
        control="input"
        type="text"
        name="projectName"
        label="Название проекта"
        placeholder={'Platforma MLM'}
      />
      <ColorSelector values={values} />
    </SettingItem>
  );
};
