import axios from 'axios';

export interface TeammateStatusDTO {
  id: number;
  name: string;
}

export const getAvailableTeammateStatuses = () => {
  return axios.get<{ data: TeammateStatusDTO[] }>('/api/partner_status/all');
};
