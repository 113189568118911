import { useRef } from 'react';
import { Dialog, Icon, IDialogType } from 'stories';
import { HelpDialog } from './components/HelpDialog/HelpDialog';

import styles from './HelpTip.module.scss';

import icon_question_24 from 'assets/sprite/24/question_24.svg';
import icon_link_20 from 'assets/sprite/20/link_20.svg';
import img_help from 'assets/images/TEMP/help_tip.svg';

export const HelpTip = () => {
  const dialogRef = useRef<IDialogType>();

  const onClick = () => {
    dialogRef.current.open();
  };

  const data = {
    title: 'Списки',
    updated: 'Обновлено: 20 Ноя 2021',
    img: img_help,
    desc: [
      'CheckIn 2ГИС — экспериментальная функция, позволяющая отмечать посещения компаний и мест и делиться информацией о визитах, отзывами и фотографиями с друзьями в социальных сетях.',
      'Благодаря сервису можно получить рекомендации друзей о местах и компаниях. Например, в 2ГИС можно будет узнать, что друг побывал в ресторане неподалёку от вас и изучить его отзыв об этом заведении.'
    ]
  };

  const buttons = [
    {
      title: 'Открыть в новой вкладке',
      icon: icon_link_20
    }
  ];

  return (
    <span className={styles.tip} onClick={onClick}>
      <Icon viewBox={icon_question_24.viewBox} id={icon_question_24.id}></Icon>
      <Dialog ref={dialogRef} title={'Справка'}>
        <HelpDialog
          title={data.title}
          updated={data.updated}
          img={data.img}
          desc={data.desc}
          buttons={buttons}
        />
      </Dialog>
    </span>
  );
};
