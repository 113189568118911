import { Icon } from 'stories';
import { selectUserProjectsSelectedId, useSelector } from 'store';
import { DPlate } from './DPlate';

import styles from './DTeam.module.scss';

import icon_userProfile_24 from 'assets/sprite/24/userProfile_24.svg';
import icon_send_24 from 'assets/sprite/24/send_24.svg';
import icon_ranking_24 from 'assets/sprite/24/ranking_24.svg';

export const DTeam = () => {
  const selected = useSelector(selectUserProjectsSelectedId);

  const data = [
    {
      icon: icon_userProfile_24,
      count: 20,
      description: (
        <>
          всего
          <br />
          клиентов
        </>
      )
    },
    {
      icon: icon_send_24,
      count: 5,
      description: (
        <>
          клиенты <br />в первой линии
        </>
      )
    },
    {
      icon: icon_ranking_24,
      count: 5,
      description: (
        <>
          всего <br /> партнеров
        </>
      )
    },
    {
      icon: icon_send_24,
      count: 3,
      description: (
        <>
          партнеры <br />в первой линии
        </>
      )
    }
  ];

  return (
    <DPlate title="Моя команда" linkName="Вся структура команды" link={`/${selected}/team`}>
      <div className={styles.row}>
        {data.map((item, index) => {
          return (
            <div className={styles.col} key={index}>
              <div className={styles.plate}>
                <Icon className={styles.icon} viewBox={item.icon.viewBox} id={item.icon.id}></Icon>
                <div className={styles.count}>{item.count}</div>
                <div className={styles.description}>{item.description}</div>
              </div>
            </div>
          );
        })}
      </div>
    </DPlate>
  );
};
