import { VideoDTO } from 'api';
import { FC } from 'react';
import { VideoCourseItem } from '../VideoCourseItem';

interface Props {
  courseVideos: VideoDTO[];
}
export const VideoCourseList: FC<Props> = ({ courseVideos }) => {
  return (
    <div>
      {courseVideos?.map((video, index) => {
        return (
          <VideoCourseItem
            index={index}
            videoId={video.id}
            key={video.id}
            rating={video.rating}
            isDone={video.watched}
            title={video.name}
            isClosed={video.locked}
            duration="5:47"
            posterUrl={video.thumbnails.default}
          />
        );
      })}
    </div>
  );
};
