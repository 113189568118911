import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  createProject,
  ErrorsFormikResponse,
  getProjects,
  ICreateProjectPayload,
  ICreateProjectResponse,
  ProjectDTO
} from 'api';
import { handleThunkCondition, PlainActionCasesType, StoreValidationErrorsType } from 'store';
import { axiosErrorHandler } from 'utils';

type ProjectsState = {
  projects: ProjectDTO[];
  selected: number;
  errors: StoreValidationErrorsType;
  status: PlainActionCasesType;
};

const initialState: ProjectsState = {
  projects: [],
  selected: null,
  errors: null,
  status: 'idle'
};

export const tryGetProjects = createAsyncThunk<ProjectDTO[], void>(
  'user/projects',
  async (payload, thunkAPI) => {
    try {
      const { data } = await getProjects();

      return data.data;
    } catch (error) {
      // TODO parse message here and pass it rejectWithValue method.
      return thunkAPI.rejectWithValue('CHANGE MSG HERE');
    }
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const tryCreateProject = createAsyncThunk<ICreateProjectResponse, ICreateProjectPayload>(
  'user/projects/create',
  async (payload, thunkAPI) => {
    try {
      const { data } = await createProject(payload);

      return data.data;
    } catch (e) {
      const error = axiosErrorHandler(e);

      if (error.type === 'axios-validation-error') {
        return thunkAPI.rejectWithValue(error.error.errors);
      }

      return thunkAPI.rejectWithValue(null);
    }
  },
  { condition: (payload, { getState }) => handleThunkCondition(getState) }
);

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setSelectedProject(state, { payload }: PayloadAction<number>) {
      state.selected = payload;
    },
    resetProjectsState() {
      return { ...initialState };
    }
  },
  extraReducers: builder => {
    builder.addCase(tryGetProjects.fulfilled, (state, { payload }) => {
      state.projects = payload;
    });

    builder
      .addCase(tryCreateProject.pending, state => {
        state.status = 'pending';
      })
      .addCase(tryCreateProject.rejected, (state, { payload }) => {
        state.errors = payload as ErrorsFormikResponse;
        state.status = 'rejected';
      })
      .addCase(tryCreateProject.fulfilled, (state, { payload }) => {
        const projectPayload = { ...payload };
        delete projectPayload.user;

        state.projects.push(projectPayload);
        state.status = 'fulfilled';
      });
  }
});

export const { setSelectedProject, resetProjectsState } = projectsSlice.actions;

const { reducer } = projectsSlice;

export default reducer;
