import { motion } from 'framer-motion';
import { ArticleHeader } from 'modules';
import {
  resetTeamState,
  selectTeamPage,
  selectUserProjects,
  tryGetTeamInfo,
  useAppDispatch,
  useSelector
} from 'store';
import { TeamTree } from './components';

import icon_info_20 from 'assets/sprite/20/info_20.svg';
import icon_calendar_20 from 'assets/sprite/20/calendar_20.svg';

import { useEffect } from 'react';

export const TeamPage = () => {
  const { status, team } = useSelector(selectTeamPage);
  const { selected } = useSelector(selectUserProjects);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(tryGetTeamInfo({ id: selected }));

    return () => {
      dispatch(resetTeamState());
    };
  }, []);

  const filters = [
    {
      icon: icon_info_20,
      label: 'Статус',
      name: 'status',
      options: [
        { key: 'все', value: 'all' },
        { key: 'не все', value: 'notAll' }
      ]
    },
    {
      icon: icon_calendar_20,
      label: 'дата добавления',
      name: 'date',
      options: [
        { key: 'все', value: 'all' },
        { key: 'не все', value: 'notAll' }
      ]
    }
  ];
  const initialValues = {
    status: filters[0].options[0].value,
    date: filters[1].options[0].value
  };

  const isEmpty = team.length === 0;
  const isLoading = status === 'idle' || status === 'pending';
  const isLoaded = status === 'fulfilled';

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <ArticleHeader
        title={'Команда'}
        filters={filters}
        initialValues={initialValues}
        disabled={isEmpty}
      />

      {isLoading && <div>Загрузка...</div>}

      {isLoaded && team.length > 0 && <TeamTree team={team} />}

      {isLoaded && team.length === 0 && <div>К сожалению, команда пока что отсутствует...</div>}
    </motion.div>
  );
};
