import { useSelector } from 'store';
import { selectCourses } from 'store/selectors/education';
import { CourseCard } from '../CourseCard';
import styles from './CourseList.module.scss';

export const CourseList = () => {
  const courses = useSelector(selectCourses);

  return (
    <div className={styles.courseList}>
      {courses.map(course => {
        return <CourseCard {...course} key={course.id} />;
      })}
    </div>
  );
};
