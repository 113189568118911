import { SettingItem } from '../Settingitem/SettingItem';
import { Item } from './Item';

export const NotificationSettings = () => {
  return (
    <SettingItem title="Настройки уведомлений" desc="Настройте уведомления на весь проект">
      <Item title={'Успешная регистрация'} name={'successRegisration'} />
      <Item title={'Входящие заявки'} name={'incomingApplication'} />
      <Item title={'Восстановление пароля'} name={'passwordRecovery'} />
      <Item title={'Окончание тестового периода'} name={'endedTrialPeriod'} />
    </SettingItem>
  );
};
