import { FC, useRef } from 'react';
import { Dialog, Icon, IDialogType } from 'stories';
import { SocialBar } from 'stories/SocialBar';
import { useBreakpoints } from 'hooks';
import { ListsCardDialog } from '../ListsCardDialog';
import { ProgressBar } from '../ProgressBar';

import icon_dots_20 from 'assets/sprite/20/dots_20.svg';
import icon_source from 'assets/sprite/20/source_20.svg';

import styles from './ListsCard.module.scss';
import icon_approve_20 from 'assets/sprite/20/approve_20.svg';

interface ListsCardProps {
  id: number;
  status: string;
  username: string;
  kindOfTask: string;
  date: string;
}

export const ListsCard: FC<ListsCardProps> = ({ status, username, kindOfTask, date }) => {
  const dialogRef = useRef<IDialogType>();
  const openDialog = () => {
    dialogRef.current.open();
  };
  const { isDesktopMin } = useBreakpoints();

  const desktopTmp = (
    <div className={styles.listCard}>
      <ProgressBar status={status} />
      <div className={styles.socials}>
        <SocialBar />
      </div>
      <div className={styles.aboutCustomer}>
        <p className={styles.customerName}>{username}</p>
        <div className={styles.aboutCustomerRight}>
          <span className={styles.kindOfTask}>
            <Icon
              className={styles.iconSource}
              viewBox={icon_source.viewBox}
              id={icon_source.id}
            ></Icon>
            <p className={styles.desc}>{kindOfTask}</p>
          </span>
          <span className={styles.date}>
            <p className={styles.dateText}>{date}</p>
          </span>
        </div>
      </div>
      <div className={styles.tools}>
        <button className={styles.action} type="button" onClick={openDialog}>
          <Icon className={styles.icon} viewBox={icon_dots_20.viewBox} id={icon_dots_20.id} />
        </button>
      </div>
    </div>
  );

  const mobileTmp = (
    <div className={styles.listCard}>
      <div className={styles.head}>
        <ProgressBar status={status} />
        <div className={styles.group}>
          <span className={styles.date}>
            <p className={styles.dateText}>{date}</p>
          </span>
          <div className={styles.tools}>
            <button className={styles.action} type="button" onClick={openDialog}>
              <Icon className={styles.icon} viewBox={icon_dots_20.viewBox} id={icon_dots_20.id} />
            </button>
          </div>
        </div>
      </div>
      <p className={styles.customerName}>{username}</p>
      <div className={styles.footer}>
        <div className={styles.socials}>
          <SocialBar />
        </div>
        <div className={styles.aboutCustomer}>
          <div className={styles.aboutCustomerRight}>
            <span className={styles.kindOfTask}>
              <Icon
                className={styles.iconSource}
                viewBox={icon_source.viewBox}
                id={icon_source.id}
              />
              <p className={styles.desc}>{kindOfTask}</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const renderTemplate = () => {
    const isDestopTmp = isDesktopMin;

    if (isDestopTmp) {
      return desktopTmp;
    }

    return mobileTmp;
  };

  return (
    <>
      {renderTemplate()}
      <Dialog ref={dialogRef} title={'Просмотр заявки'}>
        <ListsCardDialog
          id={'id'}
          username={username}
          status_id={0}
          buttons={[
            {
              title: 'Сохранить',
              icon: icon_approve_20,
              type: 'submit'
            }
          ]}
        />
      </Dialog>
    </>
  );
};
