import React, { FC } from 'react';

import cn from 'classnames';
import styles from './Icon.module.scss';

export interface IIconProps {
  readonly id: string;
  readonly viewBox?: string;
  readonly className?: string;
  readonly ariaLabel?: string;
}

/**
 * An Icon is a piece of visual element, but we must ensure its accessibility while using it.
 * It can have 2 purposes:
 *
 * - *decorative only*: for example, it illustrates a label next to it. We must ensure that it is ignored by screen readers, by setting `aria-hidden` attribute (ex: `<Icon icon={check.id} />`), aria-hidden will be added automatically.
 * - *non-decorative*: it means that it delivers information. For example, an icon as only child in a button. The meaning can be obvious visually, but it must have a proper text alternative via `aria-label` for screen readers. (ex: `<Icon icon={print.id} ariaLabel="Print this document" />`)
 *
 * All icons have saved area that was added specially via designers and aligned to square with width/height `1em`.
 *
 * How to use:
 *
 * `import Face_80_O from 'assets/default/sprite/main/outline/80/Face_80_O.svg';`
 *
 * `import { Icon } from 'stories';`
 *
 * `<Icon icon={Face_80_O.id} />`
 */
export const Icon: FC<IIconProps> = ({ id, viewBox, className = '', ariaLabel }) => (
  <svg
    data-testid={id}
    className={cn(styles.icon, className)}
    viewBox={viewBox}
    aria-label={ariaLabel}
    aria-hidden={!!ariaLabel}
  >
    <use xlinkHref={`#${id}`} />
  </svg>
);
