import { FC } from 'react';
import styles from './CourseBadge.module.scss';

interface Props {
  totalCourses: number;
  completedCourses: number;
}

export const CourseBadge: FC<Props> = ({ totalCourses, completedCourses }) => {
  return (
    <div className={styles.badge}>
      {completedCourses}/{totalCourses} видео
    </div>
  );
};
