import { FC } from 'react';
import { DialogContainer, IIconProps } from 'stories';
import { DialogFooter } from 'stories/Dialog/shared/DialogFooter';
import styles from './HelpDialog.module.scss';

interface IButton {
  title: string;
  icon: IIconProps;
}
export interface IHelpDialog {
  title: string;
  updated: string;
  img: string;
  desc: string[];
  buttons: IButton[];
}

export const HelpDialog: FC<IHelpDialog> = ({ title, updated, img, desc, buttons }) => {
  return (
    <>
      <DialogContainer>
        <div className={styles.root}>
          <span className={styles.title}>{title}</span>
          <span className={styles.updated}>{updated}</span>
          <img className={styles.image} src={img} alt="Help image" />
          {desc.map((article, index) => (
            <p key={index} className={styles.article}>
              {article}
            </p>
          ))}
        </div>
      </DialogContainer>
      <DialogFooter buttons={buttons} />
    </>
  );
};
