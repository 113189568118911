import React, { FC } from 'react';
import { Icon, IIconProps } from 'stories';
import icon_approve_20 from 'assets/sprite/20/approve_20.svg';
import styles from './Notification.module.scss';

interface IButtonNotification {
  title: string;
  mode?: 'primary' | 'accent';
}

export interface INotificationProps {
  icon: IIconProps;
  user: string;
  title: string;
  desc: string;
  time: string;
  buttons?: IButtonNotification[];
  onClick: () => void;
}

export const Notification: FC<INotificationProps> = props => {
  const { icon, user, title, desc, time, buttons, onClick } = props;
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.left}>
          <span className={styles.icon}>
            <Icon viewBox={icon.viewBox} id={icon.id}></Icon>
          </span>
          <span className={styles.title}>{title}</span>
        </div>
        <span>
          <Icon
            className={styles.checkboxIcon}
            viewBox={icon_approve_20.viewBox}
            id={icon_approve_20.id}
          ></Icon>
        </span>
      </div>
      <div className={styles.body}>
        <span className={styles.desc}>
          {user && <strong className={styles.user}>{user}&nbsp;</strong>}
          <span>{desc}</span>
        </span>
        <span className={styles.time}>{time}</span>
      </div>
      {buttons && (
        <div className={styles.footer}>
          {buttons.map((button, index) => {
            return (
              <button type={'button'} key={index} className={styles.action} onClick={onClick}>
                {button.title}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};
