import { CourseDTO } from 'api';
import cn from 'classnames';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Avatar } from 'stories';
import { RatingStars } from 'stories/RatingStars';
import { CourseBadge } from '../CourseBadge';
import { CourseDate } from '../CourseDate';
import { ProgressBar } from '../ProgressBar';
import styles from './CourseCard.module.scss';

type Props = CourseDTO;

export const CourseCard: FC<Props> = ({
  id,
  name,
  rating,
  cover,
  watched_video_count,
  // video_count,
  videos,
  author
}) => {
  const title = name.length > 80 ? name.slice(0, 80) + '...' : name;

  return (
    <NavLink className={styles.courseCard} to={`/1/education/${id}`}>
      <div style={{ backgroundImage: `url(${cover})` }} className={styles.innerFirst}>
        <div className={cn(styles.wrapper, styles.topBar)}>
          <div className={styles.progressAndBadge}>
            <ProgressBar completedCourses={watched_video_count ?? 0} totalCourses={videos.length} />
            <CourseBadge completedCourses={watched_video_count ?? 0} totalCourses={videos.length} />
          </div>
          <RatingStars rating={rating} />
        </div>
      </div>
      <div className={styles.innerSecond}>
        <div className={styles.bottomBox}>
          <div className={styles.title}>
            <span className={styles.titleInner}>{title}</span>
          </div>
          <div className={styles.authorDateBlock}>
            <div className={styles.author}>
              <Avatar
                size={80}
                className={styles.authorImage}
                firstName={author.name}
                lastName={author.lastname}
                short_name={author.short_name}
                photo={author.photo}
              />
              <p className={styles.authorName}>{author.name}</p>
            </div>
            <CourseDate date={'03 Окт 2021'} />
          </div>
        </div>
      </div>
    </NavLink>
  );
};
