import { Field } from 'formik';
import { ErrorHandleCtrl } from './ErrorHandleCtrl';
import { Icon } from 'stories';

import cn from 'classnames';
import shared from '../Control.module.scss';
import styles from './SelectCtrl.module.scss';

import icon_downArrow_20 from 'assets/sprite/20/downArrow_20.svg';

export const SelectCtrl = props => {
  const {
    name,
    label,
    options = [],
    classNameGroup,
    classNameField,
    classNameLabel,
    disabled = false,
    ...rest
  } = props;

  return (
    <Field name={name} {...rest}>
      {({ field, meta }) => {
        return (
          <div className={cn(shared.group, classNameGroup)}>
            <label
              className={cn(shared.label, classNameLabel, {
                [shared.isGreen]: meta.touched && meta.error === undefined
              })}
              htmlFor={name}
            >
              {label}
            </label>
            <Field
              className={cn(shared.field, styles.select, classNameField)}
              as="select"
              id={name}
              name={name}
              disabled={disabled}
              {...field}
            >
              {options.map(option => {
                return (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                );
              })}
            </Field>
            <Icon
              className={styles.arrowIcon}
              viewBox={icon_downArrow_20.viewBox}
              id={icon_downArrow_20.id}
            ></Icon>
            <ErrorHandleCtrl error={meta.error} touched={meta.touched} />
          </div>
        );
      }}
    </Field>
  );
};
