import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { AnimatePresence, motion } from 'framer-motion';
import { DialogHeader } from './components';
import { useBreakpoints } from 'hooks';

import './Dialog.scss';
import styles from './Dialog.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MotionDialogContent = motion(DialogContent) as any;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDialogProps {
  children: React.ReactNode;
  title: string;
}

export type IDialogType = {
  open: () => void;
  close: () => void;
};

export const Dialog = forwardRef<IDialogType, IDialogProps>((props, ref) => {
  const { children } = props;
  const [showDialog, setShowDialog] = useState(false);
  const { isDesktopMin } = useBreakpoints();

  const innerRef = useRef();

  const close = () => setShowDialog(false);

  useImperativeHandle(ref, () => ({
    open() {
      setShowDialog(true);
    },
    close() {
      close();
    }
  }));

  return (
    <AnimatePresence>
      {showDialog && (
        <DialogOverlay initialFocusRef={innerRef} onDismiss={close}>
          <MotionDialogContent
            className={styles.root}
            aria-label="My Modal"
            initial={isDesktopMin ? { x: 30, y: 0, opacity: 0 } : { y: 50, x: 0, opacity: 0 }}
            animate={isDesktopMin ? { x: 0, y: 0, opacity: 1 } : { y: 0, x: 0, opacity: 1 }}
            transition={{ ease: 'easeIn', duration: 0.3 }}
            exit={isDesktopMin ? { x: 30, y: 0, opacity: 0 } : { y: 50, x: 0, opacity: 0 }}
          >
            <div className={styles.content}>
              <DialogHeader className={styles.header} onClose={close} title={props.title} />
              <div className={styles.children}>{children}</div>
            </div>
          </MotionDialogContent>
        </DialogOverlay>
      )}
    </AnimatePresence>
  );
});
