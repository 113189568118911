import { DocumentDTO } from 'api';
import axios from 'axios';

export interface ThumbnailsDTO {
  default: string;
  mqDefault: string;
  hqDefault: string;
  sdDefault: string;
  maxResDefault: string;
}

export interface AuthorDTO {
  id: number;
  login?: unknown;
  name: string;
  lastname?: string;
  full_name: string;
  short_name: string;
  photo?: string;
}

export interface VideoDTO {
  id: number;
  url: string;
  time: string;
  name: string;
  embed: string;
  rating: number;
  locked: boolean;
  watched: boolean;
  thumbnails: ThumbnailsDTO;
}

export interface CourseDTO {
  id: number;
  name: string;
  cover: string;
  rating: number;
  video_count: number;
  watched_video_count: number;
  created_at: string;
  author: AuthorDTO;
  editors: unknown[];
  videos: VideoDTO[];
  documents: DocumentDTO[];
}

export const getCourse = (courseId: number) => {
  return axios.get<{ data: CourseDTO }>(`/api/course/find/${courseId}`);
};
