import React, { FC } from 'react';
import cn from 'classnames';

import styles from './DialogContainer.module.scss';

interface IDialogContainerProps {
  children: React.ReactNode;
  paddingThin?: boolean;
}

export const DialogContainer: FC<IDialogContainerProps> = props => {
  const { children, paddingThin = false } = props;
  return (
    <div className={styles.body}>
      <div className={cn(styles.inner, { [styles.paddingThin]: paddingThin })}>{children}</div>
    </div>
  );
};
