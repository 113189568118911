import { TeammateDTO } from 'api';
import { FC, useRef, useState } from 'react';
import { Avatar, Dialog, Icon, IDialogType, SocialBar } from 'stories';
import { AnimatePresence, motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';
import { TeammateDialog } from '../TeammateDialog';
import { useBreakpoints } from 'hooks';

import cn from 'classnames';
import styles from './Teammate.module.scss';

import icon_dots_20 from 'assets/sprite/20/dots_20.svg';
import icon_plus_24 from 'assets/sprite/24/plus_24.svg';
import icon_minus_24 from 'assets/sprite/24/minus_24.svg';

export interface ITeammateProps extends Partial<TeammateDTO> {
  isFirstLevel?: boolean;
}

export const Teammate: FC<ITeammateProps> = props => {
  const {
    id,
    isFirstLevel = false,
    name,
    lastname,
    photo,
    child = [],
    child_count,
    status
  } = props;
  const [expanded, setExpanded] = useState(false);
  const dialogRef = useRef<IDialogType>();

  const handleSetExpanded = () => {
    if (child.length === 0) {
      return;
    }
    setExpanded(!expanded);
  };

  const { isDesktopMin } = useBreakpoints();

  const getLevelDescription = (count: number) => {
    switch (count) {
      case 1:
        return 'уровень';
      case 2:
      case 3:
      case 4:
        return 'уровня';
      default:
        return 'уровней';
    }
  };

  const openDialog = () => {
    dialogRef.current.open();
  };

  return (
    <div className={cn(styles.teammate, { [styles.isSecond]: !isFirstLevel })}>
      <div
        className={cn(styles.indicator, {
          [styles.isExpanded]: expanded,
          [styles.isFirstLevel]: isFirstLevel
        })}
      >
        <div
          className={cn(styles.square, { [styles.isDisabled]: child.length === 0 })}
          onClick={handleSetExpanded}
        >
          <Icon
            className={cn(styles.squareIcon, styles.isPlus)}
            viewBox={icon_plus_24.viewBox}
            id={icon_plus_24.id}
          ></Icon>
          <Icon
            className={cn(styles.squareIcon, styles.isMinus)}
            viewBox={icon_minus_24.viewBox}
            id={icon_minus_24.id}
          ></Icon>
        </div>
        <i className={styles.lineH}></i>
        <i className={styles.lineV}></i>
      </div>
      <div className={cn(styles.group, { [styles.withMB]: isFirstLevel })}>
        <div className={cn(styles.header, { [styles.isFirstLevel]: isFirstLevel })}>
          <div className={styles.caption}>
            <Avatar className={styles.avatar} photo={photo} firstName={name} lastName={lastname} />
            <div className={styles.inner}>
              <div className={styles.title}>
                <strong className={styles.name}>
                  {name}&nbsp;{lastname}
                </strong>
                {isFirstLevel && <span className={styles.level}>1й уровень</span>}
              </div>
              <div className={styles.info}>
                {isFirstLevel && status && <div className={styles.status}>{status.name}</div>}
                {!isDesktopMin && (
                  <div className={styles.treeLevels}>
                    {child_count}&nbsp;{getLevelDescription(child_count)}
                  </div>
                )}
              </div>
            </div>
            {isDesktopMin && (
              <div className={styles.treeLevels}>
                {child_count}&nbsp;{getLevelDescription(child_count)}
              </div>
            )}
          </div>
          <div className={styles.right}>
            <SocialBar />
            <div className={styles.tools}>
              <button className={styles.action} onClick={openDialog} type="button">
                <Icon
                  className={styles.icon}
                  viewBox={icon_dots_20.viewBox}
                  id={icon_dots_20.id}
                ></Icon>
              </button>
            </div>
          </div>
        </div>

        <Dialog ref={dialogRef} title={'Просмотр анкеты'}>
          <TeammateDialog
            id={id}
            firstName={name}
            lastName={lastname}
            photo={photo}
            status_id={status?.id}
          />
        </Dialog>

        {child.length > 0 && (
          <AnimatePresence initial={false}>
            {expanded && (
              <motion.section
                className={styles.section}
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: {
                    height: 'auto',
                    transition: { duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }
                  },
                  collapsed: {
                    height: 0,
                    transition: {
                      duration: 0.4,
                      ease: [0.04, 0.62, 0.23, 0.98],
                      when: 'afterChildren'
                    }
                  }
                }}
              >
                <motion.div
                  variants={{
                    collapsed: { opacity: 0, x: '100px' },
                    open: { opacity: 1, x: '0px' }
                  }}
                  transition={{ duration: 0.3 }}
                  className={styles.placeholder}
                >
                  <>
                    {child.map(nestedProps => (
                      <Teammate
                        key={uuidv4()}
                        id={nestedProps.id}
                        isFirstLevel={false}
                        lastname={nestedProps.lastname}
                        links={nestedProps.links}
                        name={nestedProps.name}
                        child_count={nestedProps.child_count}
                        photo={nestedProps.photo}
                        status={nestedProps.status}
                        child={nestedProps.child}
                      />
                    ))}
                  </>
                </motion.div>
              </motion.section>
            )}
          </AnimatePresence>
        )}
      </div>
    </div>
  );
};
