import { FC } from 'react';

import styles from './InlineDialogHeader.module.scss';

interface InlineDialogHeaderProps {
  title: string;
}

export const InlineDialogHeader: FC<InlineDialogHeaderProps> = ({ title }) => (
  <h1 className={styles.title}>{title}</h1>
);
