import { FC } from 'react';
import styles from './ProgressBar.module.scss';

interface Props {
  completedCourses: number;
  totalCourses: number;
}

export const ProgressBar: FC<Props> = ({ totalCourses, completedCourses }) => {
  const percentOfCourses = (completedCourses / totalCourses) * 100;

  return (
    <div className={styles.progressBar}>
      <div className={styles.bar} style={{ width: `${percentOfCourses + 1}%` }} />
    </div>
  );
};
