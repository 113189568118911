import { Navigate } from 'react-router-dom';
import { useSelector, selectAuthToken } from 'store';
import { FC } from 'react';

interface IAuthProps {
  checkForToken?: boolean;
  children: React.ReactNode;
}

export const Auth: FC<IAuthProps> = ({ checkForToken = true, children }) => {
  const token = useSelector(selectAuthToken);

  if (checkForToken && token) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};
