import React, { memo } from 'react';
import { Avatar, Icon } from 'stories';
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button';
import { useDispatch } from 'react-redux';
import { selectProfile, tryLogout, useSelector } from 'store';
import cn from 'classnames';
import { useBreakpoints } from 'hooks';

import styles from './ProfileDrop.module.scss';

import icon_downArrow_20 from 'assets/sprite/20/downArrow_20.svg';
import icon_logOut_24 from 'assets/sprite/24/logOut_24.svg';

export const ProfileDrop = memo(() => {
  const { photo, name, lastname } = useSelector(selectProfile);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(tryLogout());
  };

  const { isTabletMax } = useBreakpoints();

  return (
    <div className={styles.dropdown}>
      <Menu>
        {({ isExpanded }) => (
          <React.Fragment>
            <MenuButton className={styles.header}>
              <Avatar
                className={styles.avatar}
                photo={photo}
                firstName={name}
                lastName={lastname}
              />
              {!isTabletMax && (
                <>
                  <span className={styles.title}>
                    {name}&nbsp;{lastname}
                  </span>
                  <Icon
                    className={cn(styles.linkIcon, { [styles.isExpanded]: isExpanded })}
                    viewBox={icon_downArrow_20.viewBox}
                    id={icon_downArrow_20.id}
                  />
                </>
              )}
            </MenuButton>
            <MenuList className={styles.body} portal={false}>
              <MenuItem className={styles.action} onSelect={handleLogout}>
                <Icon
                  className={styles.actionIcon}
                  viewBox={icon_logOut_24.viewBox}
                  id={icon_logOut_24.id}
                ></Icon>
                <span className={styles.actionTtl}>Выйти</span>
              </MenuItem>
            </MenuList>
          </React.Fragment>
        )}
      </Menu>
    </div>
  );
});
