import { memo } from 'react';
import { Icon, IIconProps } from 'stories';
import cn from 'classnames';

import styles from './DialogFooter.module.scss';

export interface IDialogFooterButton {
  title: string;
  icon: IIconProps;
  type?: 'button' | 'submit';
  mode?: 'primary' | 'accent';
  disabled?: boolean;
  className?: string;
  onAction?: () => void;
}
export type IDialogFooterProps = { buttons: IDialogFooterButton[] };

export const DialogFooter = memo<IDialogFooterProps>(({ buttons }) => {
  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        {buttons.map((button, index) => {
          const {
            title,
            icon,
            onAction = () => {},
            type = 'button',
            mode = 'accent',
            disabled = false,
            className
          } = button;

          return (
            <button
              key={index}
              className={cn(
                styles.action,
                {
                  [styles.isPrimary]: mode === 'primary',
                  [styles.isAccent]: mode === 'accent'
                },
                className
              )}
              onClick={onAction}
              type={type}
              disabled={disabled}
            >
              <Icon className={styles.actionIcon} viewBox={icon.viewBox} id={icon.id}></Icon>
              <span>{title}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
});
